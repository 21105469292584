import { usePresets } from "@app/hooks/usePresets";
import { useProducts } from "@app/hooks/useProducts";
import { useAuthentication } from "@app/providers/AuthenticationProvider/AuthenticationProvider";
import { usePageContext } from "@app/providers/PageProvider/PageProvider";
import { Basket, basketStatusToIdMap, BasketType, DeliveryType } from "@sourceful/shared-types";
import { useQueryClient } from "@tanstack/react-query";
import { useCallback, useMemo, useState } from "react";
import { decorateItemsWithEngineResults } from "../helpers/basket-engine-helpers";
import { isBasketInitialised } from "./helpers/isBasketInitialised";
import { useBasketTotals } from "./useBasketTotals";
import { useCustomHandlingCost } from "./useCustomHandlingCost";
import { useFetchRawBasket } from "./useFetchRawBasket";
import { useFormattedBasket } from "./useFormattedBasket";
import { useMutateBasket } from "./useMutateBasket";

const emptyBasket: Basket = {
  id: "",
  type: BasketType.LIVE_PRICE,
  configurationItems: [],
  currency: "GBP",
  status: { id: basketStatusToIdMap.NEW, name: "NEW" },
  deliveryAddress: null,
  hasChanged: false,
  deliveryType: DeliveryType.STANDARD,
  shouldOffsetCarbon: true,
  dateCreated: new Date(),
  totals: null,
};

export const useFetchBasket = () => {
  const queryClient = useQueryClient();

  // TODO: this is a hack that should be removed once we add a pending status for baskets (e.g. waiting for stripe webhook to fire) https://www.notion.so/sourceful/2b268feff3f444a8a64c9272c1b1e6c9?v=59b2b9d059054a2281225a601a4ae8f2&p=a9cc3d1aaadf43c6a2497e1c294b97a3&pm=s
  // we unset the basket in memory and then set this flag to prevent `initialised` from going to false
  // we need to do this to hide the user basket from the ui even though it is not marked as completed until the stripe webook completes
  const [isBasketUnset, setIsBasketUnset] = useState(false);

  const { draftMode } = usePageContext();

  const { isLoading: isAuthLoading, isAuthenticated, user, organisation } = useAuthentication();
  const { presets, presetsLoading } = usePresets(!isAuthenticated);
  const { products, productsLoading } = useProducts(!isAuthenticated);

  // 1. Fetch raw basket
  const {
    rawBasket,
    rawBasketLoading,
    rawBasketRefetching,
    rawBasketError,
    refetchRawBasket,
    rawBasketFetched,
  } = useFetchRawBasket({
    enabled: !!user && !!organisation?.id && !presetsLoading && !productsLoading,
    userId: user?.sub || "",
  });

  // 2. format and get any stale product warnings
  const {
    formattedBasket,
    formattedBasketLoading,
    formattedBasketRefetching,
    formattedBasketError,
    formattedBasketQueryKey,
  } = useFormattedBasket({
    rawBasket,
    //isPreview: router.isPreview,
    isPreview: draftMode,
    products,
    presets,
  });

  // 3. fetch basket totals
  const {
    basketTotals,
    basketTotalsLoading,
    basketTotalsError,
    basketTotalsRefetching,
    refetchBasketTotals,
  } = useBasketTotals({
    basket: formattedBasket?.basket || null,
    staleProductWarnings: formattedBasket?.staleProductWarnings || [],
  });

  // 4. format final basket

  const basketItemsWithEngineResponse =
    formattedBasket && basketTotals
      ? decorateItemsWithEngineResults(
          formattedBasket?.basket?.configurationItems || [],
          basketTotals.engineQuotesByConfigItemId
        )
      : null;

  const basket: Basket | null = useMemo(
    () =>
      formattedBasket
        ? {
            ...formattedBasket.basket,
            configurationItems:
              basketItemsWithEngineResponse || formattedBasket.basket.configurationItems,
            totals: basketTotals?.totals || null,
          }
        : null,
    [basketItemsWithEngineResponse, basketTotals?.totals, formattedBasket]
  );

  const customHandlingCost = useCustomHandlingCost();

  const refetchBasket = useCallback(async () => {
    setIsBasketUnset(false);
    await refetchRawBasket();
  }, [refetchRawBasket]);

  const recalculateBasketTotals = useCallback(async () => {
    await refetchBasketTotals();
  }, [refetchBasketTotals]);

  const { updateBasketMutation } = useMutateBasket({
    formattedBasket,
    formattedBasketQueryKey,
    refetchBasketTotals,
  });

  const unsetBasket = useCallback(() => {
    queryClient.setQueryData(formattedBasketQueryKey, {
      basket: emptyBasket,
      staleProductWarnings: [],
    });
    setIsBasketUnset(true);
  }, [queryClient, formattedBasketQueryKey]);

  const isLoading = rawBasketLoading || formattedBasketLoading || basketTotalsLoading;

  const forceBasketSync = useCallback(async () => {
    if (!basket) return;
    await updateBasketMutation.mutateAsync(basket);
  }, [basket, updateBasketMutation]);

  const initialised = isBasketInitialised({
    isAuthLoading,
    presetsLoading,
    productsLoading,
    isAuthenticated,
    rawBasketFetched,
    isBasketUnset,
  });

  const isFetchingBasketTotals = basketTotalsLoading || basketTotalsRefetching;

  return {
    basket: basket || emptyBasket,
    totals: basketTotals?.totals || null,
    staleProductWarnings: formattedBasket?.staleProductWarnings || [],
    customHandlingCost,
    isLoading,
    isRefetching: rawBasketRefetching || formattedBasketRefetching || basketTotalsRefetching,
    isFetchingBasketTotals,
    initialised,
    forceBasketSync,
    refetchBasket,
    recalculateBasketTotals,
    updateBasketMutation,
    unsetBasket,
    rawBasketError,
    formattedBasketError,
    basketTotalsError,
  };
};
