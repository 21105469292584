import { ADDRESS_FRAGMENT } from "@gql/fragments/address/addressFragment";
import { PRESET_FRAGMENT } from "@gql/fragments/preset/presetFragment";
import { CONFIGURATION_ITEM_FRAGMENT } from "@gql/fragments/configuration/configurationItemFragment";
import gql from "graphql-tag";

export const BASKET_FRAGMENT = gql`
  fragment basketFields on product_cloud_basket {
    id
    currency
    carbon_offset
    type
    delivery_type
    delivery_metadata
    basket_status {
      id
      basket_status_name
    }
    address {
      ...addressFields
    }
    configuration_items {
      ...configurationItemFields
    }
    autostock_requested
    user_id
    last_updated
    date_created
  }
  ${ADDRESS_FRAGMENT}
  ${PRESET_FRAGMENT}
  ${CONFIGURATION_ITEM_FRAGMENT}
`;
