import {
  CLAIMS_DOMAIN,
  useAuthentication,
} from "@app/providers/AuthenticationProvider/AuthenticationProvider";
import { Basket, BasketPayload } from "@sourceful/shared-types";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { syncBasket } from "../_api";
import { basketToUpdatePayload } from "../helpers/basket-format-helpers";
import { FormattedBasketState } from "./useFormattedBasket";

export interface UseMutateBasketProps {
  formattedBasketQueryKey: any[];
  formattedBasket: FormattedBasketState | undefined;
  refetchBasketTotals: () => Promise<unknown>;
}

export const useMutateBasket = ({
  formattedBasketQueryKey,
  formattedBasket,
  refetchBasketTotals,
}: UseMutateBasketProps) => {
  const queryClient = useQueryClient();
  const { getAccessTokenSilently, user, organisation } = useAuthentication();

  const _updateBasket = async (basket: Basket) => {
    if (!user) throw new Error(`Could not sync basket - missing user`);
    const token = await getAccessTokenSilently();

    // old auth0 user id
    const userId = user?.sub || "";
    // internal user uuid
    const userUuidInternal = user ? user[CLAIMS_DOMAIN].user.uuid : "";

    const payload: BasketPayload = basketToUpdatePayload(
      basket,
      userId,
      organisation?.id,
      userUuidInternal
    );
    const newBasket = await syncBasket({ payload, token });
    return newBasket;
  };

  const updateBasketMutation = useMutation({
    mutationFn: _updateBasket,
    onMutate: async updatedBasket => {
      await queryClient.cancelQueries({ queryKey: formattedBasketQueryKey });
      // Snapshot the previous value
      const prev = formattedBasket;
      // Optimistically update to the new value
      queryClient.setQueryData(formattedBasketQueryKey, {
        ...prev,
        basket: updatedBasket,
      });
      // Return a context object with the snapshotted value
      return { prev };
    },
    // If the mutation fails,
    // use the context returned from onMutate to roll back
    onError: (_err, _newBasket, context) => {
      queryClient.setQueryData(formattedBasketQueryKey, context?.prev);
    },
    onSuccess: async () => {
      await refetchBasketTotals();
    },
  });

  return {
    updateBasketMutation,
  };
};
