import { useGraphQLMutation } from "@app/hooks/useGraphQLRequest";
import { usePresets } from "@app/hooks/usePresets";
import { useProducts } from "@app/hooks/useProducts";
import { CLAIMS_DOMAIN, useAuthentication } from "@app/providers/AuthenticationProvider/AuthenticationProvider";
import { ErrorStatus, useErrorContext } from "@app/providers/ErrorProvider/ErrorProvider";
import { useFinalMileContext } from "@app/providers/FinalMileProvider/FinalMileProvider";
import { User } from "@auth0/auth0-spa-js";
import {
  AddBasketMutation,
  AddBasketMutationVariables,
  DeleteArtworkFileResponseMutation,
  DeleteArtworkFileResponseMutationVariables,
} from "@generated/graphql";
import { ADD_BASKET } from "@gql/mutations/basket/addBasket";
import { INVALIDATE_ARTWORK_FILE_RESPONSE } from "@gql/mutations/basket/deleteArtworkFileResponse";
import { getEngineVersion } from "@lib/getEngineVersion";
import { handleAxiosError } from "@lib/handleAxiosError";
import { migrateConfigItemProduct } from "@lib/hooks/useMigrateProduct/_api";
import { addEcommerceEvent, getProductEcommerceItems } from "@lib/productEcommerceItem";
import { useDetectDuplicateConfiguratorTabs } from "@providers/ConfiguratorProviderV2/hooks/useDetectDuplicateConfiguratorTabs";
import {
  ArtworkFileResponse,
  ArtworkOption,
  Basket,
  BasketItem,
  ConfigurationItem,
  DeliveryMetadata,
  DeliveryType,
  EngineChoiceOverride,
  EngineResponse,
  FaceName,
  GQLResponse,
} from "@sourceful/shared-types";
import { attributeSelectionToApiPayload } from "@sourceful/shared-utils/engine-utils";
import React, { FunctionComponent, createContext, useContext, useRef, useState } from "react";
import { addBasketItemRecord, fetchBasketTotals } from "./_api";
import { decorateItemsWithEngineResults } from "./helpers/basket-engine-helpers";
import { formatCurrentBasketQuery } from "./helpers/basket-format-helpers";
import { ProductMigrationWarningsWithItem } from "./helpers/basketMigration/getProductMigrationWarnings";
import { checkIfCustomQuote } from "./helpers/checkIfCustomQuote";
import { useFetchBasket } from "./useFetchBasket/useFetchBasket";

// THESE WILL BE STORED IN PC/AGENCY SERVICE DBs

const ERROR_SCOPE = "Basket";

export type SubscriptionFrequency = "Weekly" | "Monthly" | "Quarterly";

export type AutostockFrequency = "Weekly" | "Monthly" | "Quarterly" | "Flexible"; // Flexible = self-service

export type PaymentPlan = "Pay on demand" | "Pay upfront";

// THESE WILL BE STORED IN PC/AGENCY SERVICE DBs

export interface AutostockOptions {
  deliveryFrequency: AutostockFrequency;
  startDate: Date;
  quantityPerDelivery: number;
  paymentPlan: PaymentPlan;
}

export interface ArtworkMetadata {
  productId?: number | null;
  templateId?: number | null;
  artworkOption: {
    id: number | null;
    name: ArtworkOption | null;
  };
  assets: {
    inner: ArtworkFileResponse | null;
    outer: ArtworkFileResponse | null;
    single_sided?: ArtworkFileResponse | null;
  };
}

// TODO remove the extra loading flags
export interface BasketContextInjectedProps {
  basket: Basket;
  staleProductWarnings: ProductMigrationWarningsWithItem<BasketItem>[];
  createInitialBasket: (items: BasketItem[]) => void;
  removeBasketItem: (item: BasketItem) => void;
  setCarbonOffset: (isOffset: boolean) => void;
  isFetchingBasketTotals: boolean;
  isFetchingBasket: boolean; // TODO remove when stable in favour of initialised?
  initialised: boolean;
  unsetBasket: () => void;
  updateBasketDeliveryOption: (args: { metadata?: DeliveryMetadata; method: DeliveryType }) => void;
  refetchBasket: () => Promise<void>;
  addBasketItem: (configurationItem: ConfigurationItem) => Promise<string>;
  recalculateBasketTotals: () => Promise<void>;
  toggleEngineQuoteSave: (shouldSave: boolean) => void;
  isAddingBasketItem: boolean;
  migrateStaleBasketItem: (updatedItem: BasketItem) => Promise<void>;
  customHandlingCost: number | null;
  forceBasketSync: () => Promise<void>;
}

export interface EngineChoiceOverridesByAttributeId {
  [attributeId: string]: EngineChoiceOverride[];
}

export interface BasketEngineOverrides {
  [basketItemId: string]: EngineChoiceOverridesByAttributeId;
}

export interface EngineResponsesByBasketItemId {
  [basketItemId: string]: EngineResponse;
}

export const BasketContext = createContext({} as BasketContextInjectedProps);

const BasketProvider: FunctionComponent<{
  configurationItemId?: string;
  children?: React.ReactNode;
}> = ({ children, configurationItemId }) => {
  const {
    basket,
    isLoading: isFetchingBasket,
    initialised,
    staleProductWarnings,
    customHandlingCost,
    refetchBasket,
    recalculateBasketTotals,
    updateBasketMutation,
    unsetBasket,
    isFetchingBasketTotals,
    forceBasketSync,
  } = useFetchBasket();

  const { finalMile } = useFinalMileContext();

  const { publishConfigurationUpdateEvent } = useDetectDuplicateConfiguratorTabs({
    currentConfigItemId: configurationItemId || null,
  });

  const isUpdating = useRef(false);

  const shouldSaveEngineQuotesRef = useRef(false);

  const { pushError } = useErrorContext();
  const [isAddingBasketItem, setIsAddingBasketItem] = useState(false);

  const { getAccessTokenSilently, user, isAuthenticated } = useAuthentication();

  const { getPresetsAsync } = usePresets(!isAuthenticated);
  const { products } = useProducts(!isAuthenticated);

  const [addBasketMutation] =
    useGraphQLMutation<AddBasketMutationVariables, GQLResponse<AddBasketMutation>>(ADD_BASKET);

  const [invalidateArtworkResponse] = useGraphQLMutation<
    DeleteArtworkFileResponseMutationVariables,
    GQLResponse<DeleteArtworkFileResponseMutation>
  >(INVALIDATE_ARTWORK_FILE_RESPONSE);

  const invalidateArtworkFileResponse = async (face: FaceName, basketItemId: string) => {
    if (!face || !basketItemId) return;
    const variables = {
      face,
      configurationItemId: basketItemId,
    };
    await invalidateArtworkResponse(variables);
  };

  const newUserBasket = async (user: User | null) => {
    if (!user || !user?.sub) {
      throw new Error("You must be logged in to add to basket");
    }

    if (!products) {
      throw new Error("newUserBasket - missing products");
    }

    // TODO: Remove this when we move to IAM internal id
    const user_id = user?.sub;
    // IAM internal user id
    const user_uuid_internal = user[CLAIMS_DOMAIN]["user"]["uuid"];

    const response = await addBasketMutation({
      currency: "GBP",
      user_id: user_id,
      user_uuid_internal: user_uuid_internal,
      carbon_offset: true,
      delivery_type: DeliveryType.STANDARD,
      delivery_metadata: {},
      configuration_items: {
        data: [],
      },
    });

    const newBasket = response?.data?.insert_product_cloud_basket_one;

    if (!newBasket) {
      throw new Error("Error creating basket");
    }

    const presets = await getPresetsAsync();
    const formattedBasket = formatCurrentBasketQuery(newBasket, products, presets);

    // FETCH ENGINE DATA
    const token = await getAccessTokenSilently();
    const { engineQuotesByConfigItemId, ...totals } = await fetchBasketTotals({
      payload: {
        basketId: formattedBasket.id as string,
        postcode: finalMile.postcode,
        countryCode: finalMile.countryCode,
        saveEngineQuote: false,
        gdsVersion: getEngineVersion(),
      },
      token,
    });

    const basketItemsWithEngineResponse = decorateItemsWithEngineResults(
      formattedBasket.configurationItems,
      engineQuotesByConfigItemId
    );

    const finalBasket: Basket = {
      ...formattedBasket,
      configurationItems: basketItemsWithEngineResponse,
      totals,
    };

    return finalBasket;
  };

  const createInitialBasket = async () => {
    try {
      const finalBasket = await newUserBasket(user || null);
      await updateBasketMutation.mutateAsync(finalBasket);
      return finalBasket;
    } catch (error) {
      handleAxiosError(error);
      throw error;
    }
  };

  const updateBasketDeliveryOption = async ({
    metadata,
    method,
  }: {
    metadata?: DeliveryMetadata;
    method: DeliveryType;
  }) => {
    if (!basket) return;

    const updatedBasket: Basket = {
      ...basket,
      deliveryType: method,
      deliveryMetadata: metadata,
      hasChanged: true,
    };
    return updateBasketMutation.mutateAsync(updatedBasket);
  };

  const removeBasketItem = async (itemToRemove: BasketItem) => {
    if (!basket) return;

    if (itemToRemove) {
      addEcommerceEvent({
        event: "remove_from_cart",
        ecommerce: {
          items: getProductEcommerceItems([
            {
              ...itemToRemove.product,
              index: 0,
              quantity: itemToRemove.quantity,
              price: itemToRemove.engineMetadata?.results?.price || 0,
              item_variant: itemToRemove.name,
            },
          ]),
        },
      });
    }

    isUpdating.current = true;
    try {
      const newBasket: Basket = {
        ...basket,
        configurationItems: basket.configurationItems.filter(item => {
          return item.id !== itemToRemove.id;
        }),
      };

      await updateBasketMutation.mutateAsync(newBasket);

      if (user && user?.sub) {
        const artworkPrintAreasToRemove = Object.values(itemToRemove.artwork.assets)
          .map(asset => asset?.face)
          .filter((face): face is FaceName => !!face);

        await Promise.all(
          artworkPrintAreasToRemove.map(async face => {
            await invalidateArtworkFileResponse(face, itemToRemove.id as string);
          })
        );
      }
    } catch (error) {
      console.error(error);
    } finally {
      isUpdating.current = false;
    }
  };

  const setCarbonOffset = async (shouldOffsetCarbon: boolean) => {
    if (!basket) return;

    try {
      const newBasket: Basket = {
        ...basket,
        shouldOffsetCarbon,
        hasChanged: true,
      };
      await updateBasketMutation.mutateAsync(newBasket);
    } catch (error) {
      pushError(
        ERROR_SCOPE,
        {
          status: ErrorStatus.fatal,
          message: "Unable to set carbon offset",
          stack: error,
        },
        true
      );
    } finally {
      isUpdating.current = false;
    }
  };

  const addBasketItem = async (configItem: ConfigurationItem) => {
    try {
      if (!user?.sub) {
        return;
      }

      if (!initialised) {
        return console.warn("Cannot add basket item, basket not initialised");
      }

      if (checkIfCustomQuote(configItem.customQuoteAttributes)) {
        throw new Error(`Cannot add item to basket - item requires a quote`);
      }

      let _basket = basket;

      setIsAddingBasketItem(true);

      if (!_basket?.id) {
        const newBasket = await createInitialBasket();
        _basket = newBasket;
      }

      const token = await getAccessTokenSilently();
      const response = await addBasketItemRecord({
        token,
        configurationItemId: configItem.id as string,
        basketId: _basket.id as string,
      });

      // alert any other tabs with the same configuration item loaded in configurator to shut down
      publishConfigurationUpdateEvent();
      await refetchBasket();
      setIsAddingBasketItem(false);

      if (response && response[0] && response[0].basket_id) {
        return response[0].basket_id;
      } else {
        return null;
      }
    } catch (error) {
      setIsAddingBasketItem(false);
      throw error;
    }
  };

  const toggleEngineQuoteSave = (shouldSave: boolean) => {
    shouldSaveEngineQuotesRef.current = shouldSave;
  };

  const migrateStaleBasketItem = async (updatedItem: BasketItem) => {
    try {
      const token = await getAccessTokenSilently();
      await migrateConfigItemProduct(
        {
          id: updatedItem.id as string,
          quantity: updatedItem.quantity,
          baseProductVersionId: updatedItem.product.versionId,
          attributeSelection: attributeSelectionToApiPayload(updatedItem.attributeSelection),
          metadata: updatedItem.metadata || {},
          name: updatedItem.name || updatedItem.product.name,
        },
        token
      );
      await refetchBasket();
    } catch (error) {
      pushError(
        ERROR_SCOPE,
        {
          status: ErrorStatus.warning,
          message: "Error Migrating Basket Item",
          stack: error,
        },
        true
      );
    }
  };

  return (
    <BasketContext.Provider
      value={{
        basket,
        createInitialBasket,
        removeBasketItem,
        isFetchingBasketTotals,
        setCarbonOffset,
        isFetchingBasket,
        initialised,
        unsetBasket,
        updateBasketDeliveryOption,
        refetchBasket,
        addBasketItem,
        recalculateBasketTotals,
        toggleEngineQuoteSave,
        isAddingBasketItem,
        staleProductWarnings,
        customHandlingCost,
        migrateStaleBasketItem,
        forceBasketSync,
      }}
    >
      {children}
    </BasketContext.Provider>
  );
};

const BasketConsumer = BasketContext.Consumer;

const useBasketContext = () => useContext(BasketContext);

export { BasketConsumer, BasketProvider, useBasketContext };
