import getAxiosClient from "@lib/getAxiosClient";
import env from "@lib/utils/env";
import {
  AttributeSelectionsByAttributeId,
  BasketItem,
  EngineMetadataSummaryByBasketItemId,
  TransportPriority,
} from "@sourceful/shared-types";
import { attributeSelectionToApiPayload } from "@sourceful/shared-utils/engine-utils";
import { apiConfig } from "../../../../apiConfig";

const DISABLE_ENGINE_CACHING = env("DISABLE_ENGINE_CACHING");

export const engineAxiosClient = getAxiosClient(
  undefined,
  {
    baseURL: apiConfig.engineApiOrigin,
  },
  undefined,
  DISABLE_ENGINE_CACHING !== "true"
);

export const generateEngineAttributesPayload = (attributes: AttributeSelectionsByAttributeId) => {
  const product_attributes = attributeSelectionToApiPayload(attributes, {
    excludedAttributeTypes: ["colour"], // colour doesn't need to be passed to engine
    excludedAttibuteIds: Object.values(attributes) // filter out attributes flagged with noVariantCheck
      .filter(attributeSelection => !!attributeSelection?.attribute?.noVariantCheck)
      .map(attributeSelection => attributeSelection?.attribute?.id),
  });

  return product_attributes;
};

export interface EngineOptions {
  postcode?: string;
  write_to_db?: boolean;
  transportPriority?: TransportPriority;
  split_delivery_quantities:
    | {
        fast: number;
        slow: number;
      }
    | undefined;
  one_step_change_attributes?: string[];
}

export type DecorateItemWithEngineResults = (
  items: BasketItem[],
  engineMetadataById: EngineMetadataSummaryByBasketItemId
) => BasketItem[];

export const decorateItemsWithEngineResults: DecorateItemWithEngineResults = (
  items: BasketItem[],
  engineMetadataById: EngineMetadataSummaryByBasketItemId
) => {
  return items.map(item => {
    return {
      ...item,
      engineMetadata: engineMetadataById[item.id]?.quote || null,
    };
  });
};
