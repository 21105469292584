import { useGraphQLQuery } from "@app/hooks/useGraphQLRequest";
import { CurrentBasketQuery, CurrentBasketQueryVariables } from "@generated/graphql";
import { CURRENT_BASKET } from "@gql/queries/basket/getCurrentBasket";
import { GQLResponse } from "@sourceful/shared-types";

export interface UseFetchRawBasketProps {
  enabled: boolean;
  userId: string;
}
export const useFetchRawBasket = ({ enabled, userId }: UseFetchRawBasketProps) => {
  const {
    query,
    isInitialLoading: rawBasketLoading,
    isRefetching: rawBasketRefetching,
    isSuccess: rawBasketFetched,
    error: rawBasketError,
    refetch: refetchRawBasket,
  } = useGraphQLQuery<CurrentBasketQueryVariables, GQLResponse<CurrentBasketQuery>>(
    CURRENT_BASKET,
    {
      variables: {
        user_id: userId,
      },
      enabled,
      staleTime: 0,
      cacheTime: 0,
    }
  );

  const rawBasket = query?.data?.product_cloud_basket
    ? query?.data?.product_cloud_basket[0] ?? null
    : null;

  return {
    rawBasket,
    rawBasketLoading,
    rawBasketFetched,
    rawBasketRefetching,
    rawBasketError,
    refetchRawBasket,
  };
};
