import { DirectPurchaseProduct, PaymentType, ProductMain } from "@sourceful/shared-types";

interface BasketProduct extends ProductMain {
  quantity?: number;
  price?: number;
  item_variant?: string;
  index: number;
}

interface EcommerceProduct {
  item_id: string;
  item_name: string;
  affiliation: string;
  coupon: string;
  currency: string;
  discount: number;
  index: number;
  item_brand: string;
  item_category: string;
  item_category2: string;
  item_category3: string;
  item_category4: string;
  item_category5: string;
  item_list_id: string;
  item_list_name: string;
  item_variant: string;
  location_id: string;
  price: number;
  quantity: number;
}

export const getDirectPurchaseProductEcommerceItems = (
  products: DirectPurchaseProduct[]
): EcommerceProduct[] => {
  return products.map(product => ({
    item_id: product._id,
    item_name: product.name,
    affiliation: "Sourceful Shop",
    coupon: "",
    currency: "GBP",
    discount: 0,
    index: 0,
    item_brand: "Sourceful",
    item_category: "direct-purchase",
    item_category2: "",
    item_category3: "",
    item_category4: "",
    item_category5: "",
    item_list_id: "",
    item_list_name: "",
    item_variant: "",
    location_id: "",
    price: product.price.GBP || 0,
    quantity: 1,
  }));
};

export const getProductEcommerceItems = (products: BasketProduct[]): EcommerceProduct[] => {
  return products.map(product => ({
    item_id: product.id,
    item_name: product.name,
    affiliation: "Sourceful Shop",
    coupon: "",
    currency: "GBP",
    discount: 0,
    index: product.index || 0,
    item_brand: "Sourceful",
    item_category: product.mainCategory.name,
    item_category2: "",
    item_category3: "",
    item_category4: "",
    item_category5: "",
    item_list_id: "",
    item_list_name: "",
    item_variant: product.item_variant || "",
    location_id: "",
    price: product.price || 0,
    quantity: product.quantity || 1,
  }));
};

interface EcommerceEvent {
  event: string;
  ecommerce: {
    payment_type?: PaymentType;
    currency?: string;
    value?: number;
    tax?: number;
    coupon?: string;
    shipping?: number;
    affiliation?: string;
    transaction_id?: string;
    items: EcommerceProduct[];
  };
}

export const addEcommerceEvent = (event: EcommerceEvent) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({ ecommerce: null });

  window.dataLayer.push(event);
};
