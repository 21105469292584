import gql from "graphql-tag";
import { PRESET_FRAGMENT } from "../preset/presetFragment";

export const CONFIGURATION_ITEM_FRAGMENT = gql`
  fragment configurationItemFields on product_cloud_configuration_item {
    id
    basket_id
    quote_id
    name
    carbon_offset
    base_product_external_id
    base_product_version_external_id
    quantity
    attribute_selection
    currency
    user_id
    org_id
    custom_quote_attributes
    artwork_thumbnails
    arden_model_id
    max_lead_time
    metadata
    engine_metadata
    date_created
    revisions(order_by: { createdDate: desc }) {
      id
      metadata
      flood_colours
      user_signed_off
      createdDate
      assets {
        id
        face
      }
    }
    basket {
      id
      status_id
    }
    artwork_option {
      id
      option_name
    }
    preset {
      ...presetFields
    }
    sourceful_preset_id
    configuration_item_artworks(where: { active: { _eq: true } }) {
      id
      artwork_file_data
      face
      active
    }
  }
  ${PRESET_FRAGMENT}
`;

export const INTERNAL_CONFIGURATION_ITEM_FRAGMENT = gql`
  fragment internalConfigurationItemFields on product_cloud_configuration_item {
    id
    quote_id
    name
    carbon_offset
    base_product_external_id
    base_product_version_external_id
    quantity
    attribute_selection
    currency
    user_id
    org_id
    custom_quote_attributes
    artwork_thumbnails
    arden_model_id
    max_lead_time
    metadata
    engine_metadata
    date_created
    revisions(order_by: { createdDate: desc }) {
      id
      metadata
      flood_colours
      user_signed_off
      createdDate
      assets {
        id
        face
      }
    }
    artwork_option {
      id
      option_name
    }
    preset {
      ...presetFields
    }
    sourceful_preset_id
    configuration_item_artworks(where: { active: { _eq: true } }) {
      id
      artwork_file_data
      face
      active
    }
  }
  ${PRESET_FRAGMENT}
`;
