import { AttributeValueType, BasicValue } from "@sourceful/shared-types";

export const isFscCheckNeeded = (
  fscValue: AttributeValueType | AttributeValueType[] | null
): boolean => {
  if (!fscValue) return false;

  const singleValue = Array.isArray(fscValue)
    ? (fscValue[0] as BasicValue).value
    : (fscValue as BasicValue).value;

  const finalValue = singleValue.toLowerCase();
  return finalValue !== "no" && finalValue !== "none";
};
