export interface IsBasketInitialisedArgs {
  isAuthLoading: boolean;
  presetsLoading: boolean;
  productsLoading: boolean;
  isAuthenticated: boolean;
  rawBasketFetched: boolean;
  isBasketUnset: boolean;
}

export const isBasketInitialised = ({
  isAuthLoading,
  presetsLoading,
  productsLoading,
  isAuthenticated,
  rawBasketFetched,
  isBasketUnset,
}: IsBasketInitialisedArgs): boolean => {
  return (
    (!isAuthLoading &&
      !presetsLoading &&
      !productsLoading &&
      (rawBasketFetched || isBasketUnset)) ||
    // if user is not authenticated, we return true as we dont load products and presets now all the time
    (!isAuthenticated && !isAuthLoading)
  );
};
