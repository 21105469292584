interface Args {
  isActiveVersion: boolean;
  isLatestVersion: boolean;
  isPreview: boolean;
  isRfq: boolean;
}
export const isProductStale = ({
  isActiveVersion,
  isLatestVersion,
  isPreview,
  isRfq,
}: Args): boolean => {
  if (isRfq) return false;
  const isActive = isActiveVersion;
  const isPendingLatestProduct = !isActiveVersion && isLatestVersion && isPreview;

  return !isActive && !isPendingLatestProduct;
};
