import { AttributeSelection, BasicValue, PrintArea } from "@sourceful/shared-types";

export const getSelectedPrintAreas = (selectedAttributes: AttributeSelection[]): PrintArea[] => {
  const areasSelectedForPrint: PrintArea[] = selectedAttributes
    .filter(selection => {
      return (
        selection.attribute.type === "printColour" &&
        (selection?.value as BasicValue)?.value !== "Blank"
      );
    })
    .map(selection => selection.attribute.printArea)
    .filter((i): i is PrintArea => !!i);

  return areasSelectedForPrint;
};
