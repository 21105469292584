import {
  AttributeSelection,
  AttributeSelectionsByAttributeId,
  AttributeValueType,
  BasicValue,
  CUSTOM_TRANSPORT_ID,
  ColourValue,
  SizeValue,
} from "@sourceful/shared-types";
import isEqual from "lodash/isEqual";

export const getQuantitySelection = (attributes: AttributeSelectionsByAttributeId) => {
  return Object.values(attributes).find(selection => selection.attribute.type === "quantity");
};

export const getSizeSelection = (attributes: AttributeSelectionsByAttributeId) => {
  return Object.values(attributes).find(selection => selection.attribute.type === "size");
};

export const getQuantity = (attributes: AttributeSelectionsByAttributeId) => {
  const qtySelection = getQuantitySelection(attributes)?.value;

  if (Array.isArray(qtySelection)) {
    throw new Error("Quantity value should not be stored as array");
  }

  if (!qtySelection) {
    throw new Error("No Quantity Selected");
  }

  return Number((qtySelection as BasicValue).value);
};

export const checkIfSplitDeliveryOnly = (customQuoteAttributes: string[] = []): boolean => {
  return customQuoteAttributes.length === 1 && customQuoteAttributes[0] === CUSTOM_TRANSPORT_ID;
};

export const checkHasChoiceChanged = (
  oldSelection: AttributeSelection,
  newSelection: AttributeSelection
): boolean => {
  const oldValue = oldSelection.value;
  const newValue = newSelection.value;
  // standardise values into arrays for ease of processing
  const oldValueIds = Array.isArray(oldValue)
    ? (oldValue as AttributeValueType[]).map(item => item.id).sort()
    : [oldValue.id];

  const newValueIds = Array.isArray(newValue)
    ? (newValue as AttributeValueType[]).map(item => item.id).sort()
    : [newValue.id];

  const hasValueChanged = !isEqual(oldValueIds, newValueIds);

  return hasValueChanged;
};

export const pickAttributeValues = (
  attributes: AttributeSelectionsByAttributeId,
  keys: string[]
): { [key: string]: SizeValue | BasicValue | ColourValue } => {
  return Object.keys(attributes).reduce((acc, attributeKey: string) => {
    keys.forEach(key => {
      if (attributeKey.indexOf(key) === 0) {
        //@ts-ignore
        acc[key] = attributes[attributeKey].value;
      }
    });
    return acc;
  }, {});
};
