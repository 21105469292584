import { migrateConfigItemProduct } from "@lib/hooks/useMigrateProduct/_api";
import { formatRawOfflineArtwork } from "@providers/ConfiguratorProviderV2/helpers/format-helpers";
import { attributeSelectionToApiPayload } from "@providers/ConfiguratorProviderV2/helpers/helpers";
import { Basket, BasketItem } from "@sourceful/shared-types";
import keyBy from "lodash/keyBy";
import { ProductMigrationWarningsWithItem } from "../../helpers/basketMigration/getProductMigrationWarnings";

export const automigrateBasketItems = async (
  basket: Basket,
  staleItemsWithoutWarnings: ProductMigrationWarningsWithItem<BasketItem>[],
  token: string
) => {
  const itemsToBeAutoMigrated = staleItemsWithoutWarnings.map(i => i.item);
  if (itemsToBeAutoMigrated.length < 1) {
    return basket;
  }

  const itemsToBeAutoMigratedById = keyBy(itemsToBeAutoMigrated, "id");

  //  update stale items that can be auto migrated
  const results = await Promise.allSettled(
    itemsToBeAutoMigrated.map(async item => {
      const updateDelta = await migrateConfigItemProduct(
        {
          id: item.id as string,
          quantity: item.quantity,
          baseProductVersionId: item.product.versionId,
          attributeSelection: attributeSelectionToApiPayload(item.attributeSelection),
          metadata: item.metadata || {},
          name: item.name || item.product.name,
        },
        token
      );

      // server may have updated artwork faces as part of print sides migration - update in-memory item to reflect these changes
      if (updateDelta?.configuration_item_artworks) {
        itemsToBeAutoMigratedById[item.id].artwork.assets = formatRawOfflineArtwork(
          updateDelta.configuration_item_artworks
        );
      }
    })
  );

  for (let i = 0; i < results.length; i++) {
    const result = results[i];
    const item = itemsToBeAutoMigrated[i];
    if (result.status === "rejected") {
      console.error(`Failed to auto-migrate config item ${item.id}`);
    }
  }

  return {
    ...basket,
    configurationItems: basket.configurationItems.map(item => {
      if (itemsToBeAutoMigratedById[item.id]) {
        return itemsToBeAutoMigratedById[item.id] as BasketItem;
      }
      return item;
    }),
  };
};
