import { ProductCloudRole } from "@sourceful/shared-utils/rbac";

const HASURA_DEFAULT_ROLE_HEADER = "x-hasura-role";

interface GetHeaderArgs {
  token?: string;
  role?: ProductCloudRole;
}

export const getAuthHeaders = ({ token, role }: GetHeaderArgs) => {
  let headers: { [index: string]: any } = {};

  headers[HASURA_DEFAULT_ROLE_HEADER] = ProductCloudRole.PUBLIC;

  if (role) {
    headers[HASURA_DEFAULT_ROLE_HEADER] = role;
  }

  if (token) {
    headers["Authorization"] = `Bearer ${token}`;
    return headers;
  }

  return headers;
};
