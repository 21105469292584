import GuestConfigItemManager from "@lib/GuestConfigItemManager/GuestConfigItemManager";
import {
  AttributeSelection,
  AttributeValueType,
  ProductPreset,
  AttributeSelectionsByAttributeId,
  ConfigurationItem,
  ConfigurationItemCreationAttrs,
  BasicValue,
} from "@sourceful/shared-types";
import isEqual from "lodash/isEqual";
import { isGuestConfigItem } from "@sourceful/shared-utils/configuration-utils";

export const attributeSelectionToApiPayload = (
  attributes: {
    [attributeId: string]: AttributeSelection;
  },
  options?: {
    excludedAttributeTypes: string[];
    excludedAttibuteIds?: string[];
  }
) => {
  let result: { [index: string]: AttributeValueType | AttributeValueType[] } = {};

  for (let attributeId in attributes) {
    const attributeSelection = attributes[attributeId];

    if (
      options?.excludedAttributeTypes.includes(attributeSelection?.attribute.type) ||
      options?.excludedAttibuteIds?.includes(attributeSelection?.attribute.id)
    ) {
      continue;
    }

    result[attributeSelection.attribute.id] = attributeSelection.value;
  }

  return result;
};

export const checkIfConfigItemHasEjectedFromPreset = (
  preset: ProductPreset,
  selectedAttributes: AttributeSelectionsByAttributeId
) => {
  const disgregardedAttributes = ["size", "quantity"]; // size and qty do not affect a preset

  for (let attribute of preset.fixedAttributes) {
    const attributeMeta = selectedAttributes[attribute];
    const attributeType = attributeMeta?.attribute?.type;

    if (disgregardedAttributes.includes(attributeType)) {
      continue;
    }

    if (!attributeType) {
      console.error(`Fixed attribute ${attribute} is not in attribute selection, skipping`);
      continue;
    }

    // standardise values into arrays for ease of processing
    const selectedValue = selectedAttributes[attribute].value;
    const selectedValueIds = Array.isArray(selectedValue)
      ? (selectedValue as AttributeValueType[]).map(item => item.id).sort()
      : [selectedValue.id];

    const expectedValue = preset.attributes[attribute].value;
    const expectedValueIds = Array.isArray(expectedValue)
      ? (expectedValue as AttributeValueType[]).map(item => item.id).sort()
      : [expectedValue.id];

    if (!isEqual(selectedValueIds, expectedValueIds)) {
      return true;
    }
  }

  return false;
};

export const checkIfMultipleTransportOptions = (basketItem: ConfigurationItem) => {
  return (
    basketItem?.engineMetadata?.transport_options &&
    Object.keys(basketItem?.engineMetadata?.transport_options).length > 1
  );
};

export const removeGuestBasketItemIdPrefix = (id: string | number) => {
  const basketItemId = id.toString();
  const hasGuestPrefix = isGuestConfigItem(basketItemId);

  return hasGuestPrefix ? basketItemId.slice(2) : basketItemId;
};

interface TransferConfigItemArgs {
  createConfigurationItem: (item: ConfigurationItemCreationAttrs) => Promise<ConfigurationItem>;
  userId: string;
  userUuidInternal: string;
  orgId: string;
  guestDraftManager?: GuestConfigItemManager;
}

export const transferConfigItemToUser = async ({
  createConfigurationItem,
  userId,
  userUuidInternal,
  orgId,
  guestDraftManager = new GuestConfigItemManager(),
}: TransferConfigItemArgs) => {
  const guestConfigItem = await guestDraftManager.getItem();

  if (guestConfigItem) {
    const {
      id,
      name,
      quantity,
      product,
      attributeSelection,
      preset,
      customQuoteAttributes,
      metadata,
    } = guestConfigItem;
    console.log("Transferring draft config item");

    try {
      const item = await createConfigurationItem({
        id: removeGuestBasketItemIdPrefix(id),
        name,
        hasChanged: false,
        quantity,
        product,
        attributeSelection,
        preset,
        artworkThumbnails: undefined,
        baseProductId: product.baseProductId,
        baseProductVersionId: product.versionId,
        engineMetadata: null,
        metadata,
        customQuoteAttributes,
        artwork: {
          assets: {},
        },
        currency: "GBP",
        userId,
        userUuidInternal,
        orgId,
      });

      console.log("transferred config item", item);
      guestDraftManager.removeItem();
      return item;
    } catch (err) {
      if (
        err instanceof Error &&
        err.message ===
          `Uniqueness violation. duplicate key value violates unique constraint "configuration_item_pkey"`
      ) {
        console.log("Duplicate item found, removing guest draft");
        guestDraftManager.removeItem();
      } else {
        throw err;
      }
    }
  }
};

interface HasQuantityValueChangedProps {
  oneStepChangeAttributes: string[];
  updatedAttributes: AttributeSelectionsByAttributeId;
  originalAttributes: AttributeSelectionsByAttributeId;
}

export const hasQuantityValueChanged = ({
  oneStepChangeAttributes,
  updatedAttributes,
  originalAttributes,
}: HasQuantityValueChangedProps) => {
  let hasQuantityChanged = false;
  oneStepChangeAttributes?.forEach(attribute => {
    const originalSelection = originalAttributes[attribute];
    const originalValue = originalSelection?.value
      ? (originalSelection.value as BasicValue).id
      : undefined;

    const newSelection = updatedAttributes[attribute];
    const updatedValue = newSelection?.value ? (newSelection.value as BasicValue).id : undefined;

    if (newSelection.attribute.type === "quantity" && originalValue !== updatedValue) {
      hasQuantityChanged = true;
    }
  });

  return hasQuantityChanged;
};
