import { useAuthentication } from "@app/providers/AuthenticationProvider/AuthenticationProvider";
import { useQuery } from "@tanstack/react-query";
import { fetchCustomHandlingCost } from "../_api";

export const useCustomHandlingCost = (): number | null => {
  const { getAccessTokenSilently, user } = useAuthentication();
  const { data: customHandlingCost } = useQuery({
    queryKey: ["customHandlingCost"],
    queryFn: async () => {
      const token = await getAccessTokenSilently();
      return fetchCustomHandlingCost(token);
    },
    enabled: !!user,
  });

  return customHandlingCost?.customHandlingCostPence || null;
};
