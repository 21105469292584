import {
  BasketItem,
  ConfigurationItem,
  ProductMain,
  SanityProductPreset,
} from "@sourceful/shared-types";
import {
  getProductMigrationWarnings,
  ProductMigrationWarningsWithItem,
} from "./getProductMigrationWarnings";
import { getLatestProductVersion } from "./getLatestProductVersion";
import { getDefaultPresetForProduct } from "./getDefaultPresetForProduct";

export interface SortStaleConfigItemsReturnType<T extends BasketItem | ConfigurationItem> {
  staleItemsWithoutWarnings: ProductMigrationWarningsWithItem<T>[];
  staleItemsWithWarnings: ProductMigrationWarningsWithItem<T>[];
}

export const sortStaleConfigItems = <T extends BasketItem | ConfigurationItem>(
  items: T[],
  products: ProductMain[],
  presets: SanityProductPreset[],
  isPreview: boolean
): SortStaleConfigItemsReturnType<T> => {
  const staleItemsWithoutWarnings: ProductMigrationWarningsWithItem<T>[] = [];
  const staleItemsWithWarnings: ProductMigrationWarningsWithItem<T>[] = [];

  items.forEach(item => {
    const latestProductVersion = getLatestProductVersion(item.product.baseProductId, products);

    if (!latestProductVersion) {
      throw new Error("Could not find latest product version");
    }

    const defaultPreset = getDefaultPresetForProduct(
      presets,
      latestProductVersion?.baseProductId,
      latestProductVersion?.versionId
    );

    const { warnings, item: updatedItem, isStale } = getProductMigrationWarnings({
      item,
      latestProductVersion,
      defaultPreset,
      isPreview,
    });

    if (isStale && !warnings) {
      staleItemsWithoutWarnings.push({ warnings, item: updatedItem, isStale });
      return updatedItem;
    } else if (isStale) {
      staleItemsWithWarnings.push({ warnings, item: updatedItem, isStale });
    }
  });

  return { staleItemsWithoutWarnings, staleItemsWithWarnings };
};
