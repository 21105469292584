import gql from "graphql-tag";

export const PRESET_FRAGMENT = gql`
  fragment presetFields on product_cloud_preset {
    id
    base_product_external_id
    base_product_version_external_id
    attributes
    fixed_attributes
    created_by_user_uid
    created_by_user_uuid_internal
    is_sourceful_preset
    last_updated
    org_id
    preset_name
    request_for_quote
    preset_status {
      id
      preset_status_name
    }
  }
`;
