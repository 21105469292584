// TODO Decomission - moved to configurationProvider

import {
  Basket,
  ConfigurationItemCreationAttrs,
  BasketPayload,
  Address,
  ArrayElement,
  AttributeValueType,
  ProductMain,
  ProductPreset,
  ArtworkOption,
  SanityProductPreset,
  BasketType,
  DeliveryType,
  ConfigurationItemPayload,
  ProductType,
  BasketItem,
  ConfigurationItem,
  StudioRevisionBasic,
} from "@sourceful/shared-types";
import {
  engineResponseToMetadataSummary,
  createStringHashFromAttributePayload,
} from "@sourceful/shared-utils/engine-utils";
import { getAttributeShortId } from "@sourceful/shared-utils/product-utils";
import keyBy from "lodash/keyBy";
import { Currency } from "dinero.js";
import {
  BasketFieldsFragment,
  ConfigurationItemFieldsFragment,
  CurrentBasketQuery,
  Product_Cloud_Configuration_Item_Insert_Input,
} from "generated/graphql";
import { attributeSelectionToApiPayload } from "../../ConfiguratorProviderV2/helpers/helpers";
import { stringArrayToPostgresTextArray } from "@lib/stringArrayToPostgresTextArray";
import { decorateAttributeValues } from "@sourceful/shared-utils/configuration-utils";
import { formatRawOfflineArtwork } from "@providers/ConfiguratorProviderV2/helpers/format-helpers";

// TODO: reinstate once custom presets have been implemented with uuid for primary key
// export const formatPresetQueryResult = (
//   rawPreset: PresetFieldsFragment,
//   product: ProductMain
// ): ProductPreset => {
//   return {
//     id: rawPreset.id.toString(),
//     name: rawPreset.preset_name,
//     attributes: decorateAttributeValues(rawPreset.attributes, product),
//     fixedAttributes: rawPreset.fixed_attributes,
//     baseProductId: rawPreset.base_product_external_id,
//     baseProductVersionId: rawPreset.base_product_version_external_id,
//     isSourcefulPreset: false,
//   };
// };

export type CurrentBasketQueryResult = ArrayElement<CurrentBasketQuery["product_cloud_basket"]>;

export const getCorePresetFields = ({
  id,
  baseProductId,
  baseProductVersionId,
  name,
  attributes,
  hasChanged,
  isDefault,
}: SanityProductPreset): ProductPreset => {
  return {
    id,
    baseProductId,
    baseProductVersionId,
    name,
    attributes,
    fixedAttributes: [],
    hasChanged,
    isDefault,
    isSourcefulPreset: true,
  };
};

export const formatBasketItemFragment = (
  item: ConfigurationItemFieldsFragment,
  product: ProductMain,
  sourcefulPreset: ProductPreset | null
): BasketItem => {
  const {
    id,
    quantity,
    artwork_option,
    carbon_offset,
    configuration_item_artworks,
    artwork_thumbnails,
    currency,
    metadata,
    basket_id,
    revisions,
    engine_metadata,
    date_created,
  } = item;

  // console.log("format basket item fragment", { item, product, basket_item_artworks });

  if (!product) {
    console.error(`Could not find product for basket item`, item);
  }

  const assets = formatRawOfflineArtwork(configuration_item_artworks);
  // TODO: currently we only support default sourceful presets that live in sanity - once we update the presets table
  // in product-cloud-api db then we need to check for the presence of a custom preset and set that as the preset of the basket item

  const artworkThumbnails = artwork_thumbnails && artwork_thumbnails.thumbnails;
  const { heroThumbnail = undefined } = artwork_thumbnails ?? {};

  const isRfq = product?.type === ProductType.RFQ;

  const formattedItem: BasketItem = {
    currency: currency as Currency,
    id,
    basketId: basket_id,
    name: item.name || sourcefulPreset?.name,
    quantity,
    product,
    preset: sourcefulPreset ? getCorePresetFields(sourcefulPreset) : null,
    ardenModelId: item?.arden_model_id ? item.arden_model_id : undefined,
    customQuoteAttributes:
      item.custom_quote_attributes?.map((attrId: string) =>
        getAttributeShortId(attrId, product.baseProductId)
      ) || [],
    attributeSelection: isRfq ? {} : decorateAttributeValues(item.attribute_selection, product),
    rfqAnswers: isRfq ? item.attribute_selection : undefined,
    artworkThumbnails,
    heroThumbnail,
    artwork: {
      artworkOption: {
        id: artwork_option?.id || null,
        name: artwork_option?.option_name as ArtworkOption,
      },
      assets,
    },
    shouldOffsetCarbon: carbon_offset,
    engineMetadata: engine_metadata || null,
    hasChanged: false,
    maxLeadTime: item.max_lead_time,
    metadata,
    revisions: (revisions as StudioRevisionBasic[]) || [],
    userId: item.user_id,
    orgId: item.org_id,
    dateCreated: date_created,
  };

  return formattedItem;
};

export interface ProductsById {
  [baseProductId: number]: { [versionId: number]: ProductMain };
}

export const sortProductsByIds = (products: ProductMain[]): ProductsById => {
  return products.reduce((output, current) => {
    if (!output[current.baseProductId]) {
      output[current.baseProductId] = {};
    }

    output[current.baseProductId][current.versionId] = current;
    return output;
  }, {} as ProductsById);
};

// TODO: maybe this should be calculated server-side via a REST api?
export const formatCurrentBasketQuery = (
  rawBasket: CurrentBasketQueryResult,
  products: ProductMain[],
  sourcefulPresets: SanityProductPreset[]
): Basket => {
  const {
    id,
    currency,
    basket_status,
    carbon_offset,
    address,
    configuration_items,
    user_id,
    date_created,
  } = rawBasket;

  const productMainByBaseProductId = sortProductsByIds(products);

  const sourcefulPresetsById = keyBy(sourcefulPresets, "id");

  const _configurationItems = configuration_items.map((item: ConfigurationItemFieldsFragment) => {
    const baseProducts = productMainByBaseProductId[item.base_product_external_id];
    const product = baseProducts ? baseProducts[item.base_product_version_external_id] : null;

    if (!product) {
      console.error("Missing product for config item", {
        configurationItem: item.id,
        baseProductId: item.base_product_external_id,
        versionId: item.base_product_version_external_id,
      });
      return null;
    }

    const preset = item.sourceful_preset_id
      ? sourcefulPresetsById[item.sourceful_preset_id]
      : item.preset
      ? sourcefulPresetsById[`preset-${item.preset.id}`] // TODO: remove this once split between sourceful and customer presets is finalised
      : null;

    if (!preset) {
      console.warn(`Could not find preset for item`, { rawBasket, sourcefulPresets });
    }

    return formatBasketItemFragment(item, product, preset ? getCorePresetFields(preset) : null);
  });

  const configurationItems = _configurationItems.filter((item): item is BasketItem => !!item);

  return {
    id,
    configurationItems,
    type: rawBasket.type as BasketType,
    currency: currency as Currency,
    status: { id: basket_status.id, name: basket_status.basket_status_name },
    shouldOffsetCarbon: carbon_offset,
    deliveryAddress: (address as Address) || null,
    userId: user_id || "",
    hasChanged: false,
    deliveryType: rawBasket.delivery_type as DeliveryType,
    deliveryMetadata: rawBasket.delivery_metadata,
    dateCreated: new Date(date_created),
    totals: null, // fetched later
  };
};

export const prepareConfigurationItemCreationVars = (
  items: ConfigurationItemCreationAttrs[],
  basketId?: number | string
): Product_Cloud_Configuration_Item_Insert_Input[] => {
  return items.map(item => {
    const {
      quantity,
      shouldOffsetCarbon,
      preset,
      baseProductId,
      baseProductVersionId,
      artwork,
      artworkThumbnails,
      maxLeadTime,
      id,
      currency,
      userId,
      orgId,
    } = item;
    const payload: Product_Cloud_Configuration_Item_Insert_Input = {
      //Product_Cloud_Basket_Item_Insert_Input = {
      id,
      name: item.name,
      quantity,
      currency,
      carbon_offset: !!shouldOffsetCarbon,
      arden_model_id: item.ardenModelId,
      artwork_thumbnails: artworkThumbnails,
      attribute_selection: attributeSelectionToApiPayload(item.attributeSelection),
      custom_quote_attributes: stringArrayToPostgresTextArray(item.customQuoteAttributes || []),
      sourceful_preset_id: preset?.isSourcefulPreset ? preset.id : undefined,
      base_product_external_id: baseProductId,
      base_product_version_external_id: baseProductVersionId,
      max_lead_time: maxLeadTime,
      user_id: userId,
      org_id: orgId,
    };

    if (artwork?.artworkOption?.id) {
      payload.artwork_option_id = artwork?.artworkOption?.id;
    }

    if (basketId) {
      payload.basket_id = basketId as number; // TODO: undo coercion when id transition is completed
    }

    return payload;
  });
};

export const basketToUpdatePayload = (
  basket: Basket,
  userId: string,
  orgId?: string,
  userUuidInternal?: string
): BasketPayload => {
  return {
    id: basket.id,
    configurationItems: basket.configurationItems.map((item: BasketItem) => {
      const payload: ConfigurationItemPayload = {
        id: item.id,
        name: item.name,
        ardenModelId: item.ardenModelId!,
        artworkThumbnails: item.artworkThumbnails || undefined,
        quantity: item.quantity,
        baseProductId: item.product.baseProductId,
        baseProductVersionId: item.product.versionId,
        shouldOffsetCarbon: !!item.shouldOffsetCarbon,
        hasChanged: !!item.hasChanged,
        attributeSelection: attributeSelectionToApiPayload(item.attributeSelection),
        customQuoteAttributes: item.customQuoteAttributes || [],
        sourcefulPresetId: item.preset?.isSourcefulPreset ? item.preset.id : null,
        customPresetId: null, // TODO: handle custom presets
        maxLeadTime: item.maxLeadTime,
        currency: item.currency,
        userId,
        orgId,
        userUuidInternal,
        metadata: item.metadata || {},
      };

      if (item?.artwork?.artworkOption?.id) {
        payload.artworkOptionId = item.artwork.artworkOption.id;
      }

      return payload;
    }),
    currency: basket.currency,
    shouldOffsetCarbon: !!basket.shouldOffsetCarbon,
    status: basket.status,
    deliveryAddress: basket.deliveryAddress,
    hasChanged: !!basket.hasChanged,
    deliveryType: basket.deliveryType,
    deliveryMetadata: basket.deliveryMetadata || {},
  };
};

export const getSanityProductIdsForBasket = (rawBasket: BasketFieldsFragment) => {
  return rawBasket.configuration_items.reduce((result, configurationItem) => {
    // TODO: make this less brittle
    const productId = `base-product-${configurationItem.base_product_external_id}-${configurationItem.base_product_version_external_id}`;
    return result.add(productId);
  }, new Set<string>());
};

export const configItemToBasketItem = (item: ConfigurationItem): BasketItem => {
  if (!item.engineMetadata) {
    throw new Error(`Engine metadata not found for item ${item.id}`);
  }

  const currentAttributeHash = createStringHashFromAttributePayload(
    Object.values(item.attributeSelection).reduce(
      (acc, selection) => {
        acc[selection.attribute.id] = selection.value;
        return acc;
      },
      {} as {
        [index: string]: AttributeValueType | AttributeValueType[];
      }
    )
  );

  return {
    ...item,
    engineMetadata: engineResponseToMetadataSummary(
      item.engineMetadata,
      currentAttributeHash,
      item.maxLeadTime?.speed?.max ?? null,
      item?.metadata?.transportPriority,
      item?.metadata?.splitDeliveryQuantities
    ),
  };
};
