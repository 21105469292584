interface Options {
  onEngineError?: (details: string) => void;
  onRequestError?: (request: XMLHttpRequest) => void;
  onProductCloudError?: (errors: { message: string }[]) => void;
  onGenericError?: (error: Error) => void;
}

export const handleAxiosError = (
  error: any,
  {
    onProductCloudError,
    onEngineError,
    onRequestError,
    onGenericError = error => console.error(error),
  }: Options = {}
) => {
  if (error.response?.data) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    console.error(error.response.data);

    // Product cloud error
    error.response.data?.errors &&
      onProductCloudError &&
      onProductCloudError(error.response.data.errors);

    // Engine error
    error.response.data?.errors &&
      onEngineError &&
      onEngineError(error.response.data.errors?.join(", "));
  } else if (error.request) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
    onRequestError && onRequestError(error.request);
    console.error(error.request);
  } else {
    onGenericError && onGenericError(error);
    console.error(error);
  }
};
