import {
  ProductMigrationWarningsWithItem,
  checkIfCustomQuote,
} from "@providers/BasketProvider/helpers";
import {
  Badge,
  BadgeText,
  Button,
  ButtonText,
  IconAlertExclamationFill,
  TextBody,
  TextLabel,
} from "@sourceful/design-system-v3";
import { Basket, BasketItem } from "@sourceful/shared-types";
import Link from "next/link";
import React from "react";
import { BasketProduct } from "./BasketProduct";

export interface BasketTotal {
  deliveryEstimate: string;
  co2e: {
    rating: "Excellent" | "Good" | "OK" | "Poor";
    weight: number;
    offsetCost: number;
  };
  price: number;
}

export interface BasketContentProps {
  basket: Basket;
  staleProductWarnings: ProductMigrationWarningsWithItem<BasketItem>[];
  removeBasketItem: (item: BasketItem) => void;
  handleClose: () => void;
}

export const BasketContent = React.forwardRef<HTMLAnchorElement, BasketContentProps>(
  ({ basket, removeBasketItem, staleProductWarnings, handleClose }, viewBasketRef) => {
    const { configurationItems, currency } = basket;

    const CustomQuoteItems = configurationItems.filter((item: any) =>
      checkIfCustomQuote(item.customQuoteAttributes)
    );

    const totals = basket.totals;

    const requiresQuote = CustomQuoteItems.length > 0;

    if (!configurationItems.length) {
      return (
        <TextLabel size="large" asChild>
          <p data-cy="basket-no-items-container">No items in your basket.</p>
        </TextLabel>
      );
    }
    return (
      <>
        {staleProductWarnings.length > 0 && (
          <Badge layout={"iconLeading"} colour={"warning"} size="md" className="gap-4">
            <IconAlertExclamationFill />
            <BadgeText>
              {`${staleProductWarnings.length} item${
                staleProductWarnings.length === 1 ? " needs" : "s need"
              } updating`}
            </BadgeText>
          </Badge>
        )}
        <div className="flex flex-col-reverse max-w-[280px] md:max-w-[450px]">
          <Button
            variant={"primary"}
            size="md"
            data-cy="view-basket-button"
            layout={"textOnly"}
            className="w-full"
            asChild
          >
            <Link
              href={`/basket`}
              ref={viewBasketRef}
              onClick={() => {
                handleClose();
              }}
              prefetch={false}
            >
              <ButtonText>View basket</ButtonText>
            </Link>
          </Button>

          <div>
            {configurationItems.length > 0 && (
              <ul className="flex flex-col gap-12 mb-12">
                {configurationItems.map(basketItem => {
                  return (
                    <BasketProduct
                      key={basketItem.id}
                      basketItem={basketItem}
                      basket={basket}
                      handleDelete={() => removeBasketItem(basketItem)}
                      currency={currency}
                    />
                  );
                })}
              </ul>
            )}

            <TextBody size="small" weight="medium" asChild>
              <div>
                <p className="flex justify-between py-12 border-t border-ui-grey-200">
                  <span>Delivery</span>
                  <span>{totals?.deliveryTotal?.formatted || "Error"}</span>
                </p>

                {totals?.customHandlingTotal && (
                  <p className="flex justify-between py-12 border-t border-ui-grey-200">
                    <span>Custom Handling</span>
                    <span>{totals?.customHandlingTotal?.formatted || "Error"}</span>
                  </p>
                )}
                <p className="flex justify-between py-12 border-t border-ui-grey-200">
                  <span className="flex flex-col">
                    <span>Sub-total</span>
                    <TextLabel size="medium" weight="regular" asChild>
                      <span className="text-ui-grey-600">
                        + VAT @ 20% {totals?.vatTotal?.formatted || "Error"}
                      </span>
                    </TextLabel>
                  </span>
                  <span>{requiresQuote ? "Quote" : totals?.subtotal?.formatted || "Error"}</span>
                </p>

                <p className="flex justify-between py-12 border-t border-ui-grey-200">
                  <span>Total to pay</span>
                  <span>{requiresQuote ? "Quote" : totals?.finalTotal?.formatted || "Error"}</span>
                </p>
              </div>
            </TextBody>
          </div>
        </div>
      </>
    );
  }
);

export default BasketContent;
