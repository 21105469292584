import {
  ConfigurationItem,
  ConfigurationItemCreationAttrs,
  ProductMain,
  ProductPreset,
  ArtworkOption,
  SanityProductPreset,
  ConfigurationItemPayload,
  ProductType,
  StudioRevisionBasic,
  TransportPriority,
  ArtworkAssets,
  FaceName,
  PrintArea,
} from "@sourceful/shared-types";
import { Currency } from "dinero.js";
import {
  ConfigurationItemFieldsFragment,
  Product_Cloud_Configuration_Item_Insert_Input,
} from "generated/graphql";
import { attributeSelectionToApiPayload } from "./helpers";
import { stringArrayToPostgresTextArray } from "@lib/stringArrayToPostgresTextArray";
import {
  checkIfCustomQuote,
  checkIfSplitDeliveryOnly,
  getCorePresetFields,
  engineAxiosClient,
} from "@providers/BasketProvider/helpers";
import { getAttributeShortId } from "@sourceful/shared-utils/product-utils";
import { _applyEngineResultsToItem, EngineFetchReason } from "@sourceful/shared-utils/engine-utils";
import { getEngineVersion } from "@lib/getEngineVersion";
import { decorateAttributeValues } from "@sourceful/shared-utils/configuration-utils";

export const formatSourcefulPresetForBasket = ({
  id,
  baseProductId,
  baseProductVersionId,
  name,
  attributes,
  hasChanged,
}: SanityProductPreset): ProductPreset => {
  return {
    id,
    baseProductId,
    baseProductVersionId,
    name,
    attributes,
    fixedAttributes: [],
    hasChanged,
    isSourcefulPreset: true,
  };
};

export const prepareConfigurationItemCreationVars = (
  items: ConfigurationItemCreationAttrs[]
): Product_Cloud_Configuration_Item_Insert_Input[] => {
  return items.map(item => {
    const {
      id,
      quantity,
      shouldOffsetCarbon,
      preset,
      baseProductId,
      baseProductVersionId,
      artwork,
      artworkThumbnails,
      maxLeadTime,
      currency,
      userId,
      userUuidInternal,
      orgId,
      metadata,
    } = item;
    const payload: Product_Cloud_Configuration_Item_Insert_Input = {
      id,
      name: item.name,
      quantity,
      currency,
      carbon_offset: !!shouldOffsetCarbon,
      arden_model_id: item.ardenModelId,
      artwork_thumbnails: artworkThumbnails,
      attribute_selection: attributeSelectionToApiPayload(item.attributeSelection),
      custom_quote_attributes: stringArrayToPostgresTextArray(item.customQuoteAttributes || []),
      sourceful_preset_id: preset?.isSourcefulPreset ? preset.id : undefined,
      base_product_external_id: baseProductId,
      base_product_version_external_id: baseProductVersionId,
      max_lead_time: maxLeadTime,
      user_id: userId,
      user_uuid_internal: userUuidInternal,
      org_id: orgId,
      metadata,
    };

    if (artwork?.artworkOption?.id) {
      payload.artwork_option_id = artwork?.artworkOption?.id;
    }

    return payload;
  });
};

export interface DecorateRawConfigItemArgs {
  rawItem: ConfigurationItemFieldsFragment;
  getPresetById: (presetId: string) => SanityProductPreset | null;
  getProductById: (ids: { baseProductId: number; versionId: number }) => ProductMain | null;
  postcode: string;
  disableEngineFetch?: boolean;
  oneStepChangeAttributes?: string[];
  activeAttributeId: string | null;
}

export const decorateRawConfigItem = async ({
  rawItem,
  getPresetById,
  getProductById,
  postcode,
  disableEngineFetch,
  activeAttributeId,
}: DecorateRawConfigItemArgs) => {
  const { sourceful_preset_id, base_product_external_id, base_product_version_external_id } =
    rawItem;

  const preset = getPresetById(sourceful_preset_id || "") || null;
  const product = getProductById({
    baseProductId: base_product_external_id,
    versionId: base_product_version_external_id,
  });

  if (!product) {
    throw new Error(`Product missing for config item ${rawItem.id}`);
  }

  let formattedItem = formatConfigurationItemFragment(
    rawItem,
    product,
    preset ? getCorePresetFields(preset) : null
  );

  const isCustomQuote =
    checkIfCustomQuote(formattedItem.customQuoteAttributes) &&
    !checkIfSplitDeliveryOnly(formattedItem.customQuoteAttributes); // still need to fetch engine metadata if the only reason the item is a quote is because of split delivery

  const finalItem =
    disableEngineFetch || isCustomQuote
      ? formattedItem
      : await _applyEngineResultsToItem({
          client: engineAxiosClient,
          item: formattedItem,
          engineFetchType: { type: EngineFetchReason.INITIAL_FETCH },
          activeAttributeId,
          engineOptions: {
            postcode: postcode,
            split_delivery_quantities: formattedItem.metadata?.splitDeliveryQuantities,
            transportPriority: formattedItem.metadata?.transportPriority || TransportPriority.PRICE,
          },
          engineVersion: getEngineVersion(),
        });

  return finalItem;
};

const isValidFace = (value: string): value is FaceName => {
  return Object.values<string>(PrintArea).includes(value) || ["inner", "outer"].includes(value);
};

export const formatRawOfflineArtwork = (
  configuration_item_artworks: ConfigurationItemFieldsFragment["configuration_item_artworks"]
): ArtworkAssets => {
  return (
    configuration_item_artworks?.reduce((acc, artwork) => {
      if (!isValidFace(artwork.face)) {
        console.error(`Invalid face detected - artwork id: ${artwork.id}, face: ${artwork.face}`);
        return acc;
      }

      if (artwork.face) acc[artwork.face] = artwork.artwork_file_data;
      return acc;
    }, {} as ArtworkAssets) || {}
  );
};

export const formatConfigurationItemFragment = (
  item: ConfigurationItemFieldsFragment,
  product: ProductMain,
  sourcefulPreset: ProductPreset | null
): ConfigurationItem => {
  const {
    id,
    quantity,
    artwork_option,
    carbon_offset,
    configuration_item_artworks,
    artwork_thumbnails,
    currency,
    metadata,
    date_created,
    basket_id,
    user_id,
    org_id,
    revisions,
  } = item;

  if (!product) {
    console.error(`Could not find product for basket item`, item);
  }

  const assets = formatRawOfflineArtwork(configuration_item_artworks);

  // TODO: currently we only support default sourceful presets that live in sanity - once we update the presets table
  // in product-cloud-api db then we need to check for the presence of a custom preset and set that as the preset of the basket item

  const artworkThumbnails = artwork_thumbnails && artwork_thumbnails.thumbnails;
  const { heroThumbnail = undefined } = artwork_thumbnails ?? {};

  const isRfq = product.type === ProductType.RFQ;

  const formattedItem: ConfigurationItem = {
    currency: currency as Currency,
    id,
    basketId: basket_id,
    name: item.name || sourcefulPreset?.name,
    quantity,
    product,
    preset: sourcefulPreset ? formatSourcefulPresetForBasket(sourcefulPreset) : null,
    ardenModelId: item?.arden_model_id ? item.arden_model_id : undefined,
    customQuoteAttributes:
      item.custom_quote_attributes?.map((attrId: string) =>
        getAttributeShortId(attrId, product.baseProductId)
      ) || [],
    attributeSelection: isRfq ? {} : decorateAttributeValues(item.attribute_selection, product),
    rfqAnswers: isRfq ? item.attribute_selection : undefined,
    artworkThumbnails,
    heroThumbnail,
    artwork: {
      artworkOption: {
        id: artwork_option?.id || null,
        name: artwork_option?.option_name as ArtworkOption,
      },
      assets,
    },
    shouldOffsetCarbon: carbon_offset,
    engineMetadata: null, // TODO: fetch engine metadata
    hasChanged: false,
    maxLeadTime: item.max_lead_time,
    revisions: (revisions as StudioRevisionBasic[]) || [],
    dateCreated: date_created,
    metadata,
    userId: user_id,
    orgId: org_id,
  };

  return formattedItem;
};

export const configItemToUpdatePayload = (item: ConfigurationItem): ConfigurationItemPayload => {
  const payload: ConfigurationItemPayload = {
    id: item.id,
    name: item.name,
    ardenModelId: item.ardenModelId!,
    artworkThumbnails: item.artworkThumbnails || undefined,
    quantity: item.quantity,
    baseProductId: item.product.baseProductId,
    baseProductVersionId: item.product.versionId,
    shouldOffsetCarbon: !!item.shouldOffsetCarbon,
    hasChanged: !!item.hasChanged,
    attributeSelection: attributeSelectionToApiPayload(item.attributeSelection),
    customQuoteAttributes: item.customQuoteAttributes || [],
    sourcefulPresetId: item.preset?.isSourcefulPreset ? item.preset.id : null,
    customPresetId: null, // TODO: handle custom presets
    maxLeadTime: item.maxLeadTime,
    currency: item.currency,
    userId: item.userId,
    orgId: item.orgId,
    userUuidInternal: item.userUuidInternal,
    metadata: item.metadata || {},
  };

  if (item?.artwork?.artworkOption?.id) {
    payload.artworkOptionId = item.artwork.artworkOption.id;
  }

  return payload;
};
