import { checkIfCustomQuote } from "@app/providers/BasketProvider/helpers/checkIfCustomQuote";
import { usePageContext } from "@app/providers/PageProvider/PageProvider";
import { isProductStale } from "@lib/utils/isProductStale";
import {
  Button,
  IconDocumentPencil,
  IconNavigationTrash,
  TextLabel,
} from "@sourceful/design-system-v3";
import { Basket, BasketItem, ProductMigrationWarning, ProductType } from "@sourceful/shared-types";
import {
  calculateProductionUnitCost,
  doesProductUsePence,
  formatTotalPrice,
} from "@sourceful/shared-utils/engine-utils";
import { Currency } from "dinero.js";
import Image from "next/image";
import Link from "next/link";
import { getThumbnailUrlForBasketItem } from "../../../../../components/siteHeader/headerBasket/helpers";

export interface BasketProductProps {
  basketItem: BasketItem;
  migrationWarnings?: ProductMigrationWarning[];
  basket: Basket;
  currency: Currency;
  handleDelete: () => void;
}

export const BasketProduct = ({ basketItem, handleDelete, currency }: BasketProductProps) => {
  const { draftMode } = usePageContext();

  const { product, engineMetadata, quantity } = basketItem;

  const isCustomQuote = checkIfCustomQuote(basketItem.customQuoteAttributes);

  const editLink = `/buy/${product.slug}/customise?basketItemId=${basketItem.id}`;

  const productName = basketItem?.product?.name;
  const basketItemName = basketItem?.name;

  const results = engineMetadata?.results;

  const isStale = isProductStale({
    isActiveVersion: basketItem.product.isActiveVersion,
    isLatestVersion: basketItem.product.isCurrentVersion,
    isPreview: draftMode,
    isRfq: basketItem.product.type === ProductType.RFQ,
  });

  const renderDetails = () => {
    if (isCustomQuote) {
      return "£ Quote";
    }

    const pricePence = results?.price_amount?.price_per_unit_pence;

    const formattedUnitPrice =
      pricePence && engineMetadata?.results
        ? calculateProductionUnitCost(
            engineMetadata?.results,
            currency,
            doesProductUsePence(product.baseProductId)
          ) + " per unit"
        : "Price on request";

    const totalPrice = results?.price_amount?.production_cost_pence || 0;

    const quantityMessage = `${quantity.toLocaleString()} unit${quantity === 1 ? "" : "s"}`;

    return `${formatTotalPrice(totalPrice, currency)} | ${formattedUnitPrice} | ${quantityMessage}`;
  };

  const renderLCADetails = () => {
    if (isCustomQuote) return "CO2e TBC";
    const lcaImpact = results?.lca?.global_warming.summary.impact_per_piece.toFixed(2);
    const lcaUnits = results?.lca?.global_warming.units;
    const lcaMessage = lcaImpact ? `${lcaImpact} ${lcaUnits} / unit` : "LCA unknown";
    return `${lcaMessage}`;
  };

  return (
    <li data-cy="global-header-basket-item">
      <div className="flex gap-12 items-start">
        <div className="bg-ui-grey-50 rounded-sm flex-shrink-0">
          <Image
            src={getThumbnailUrlForBasketItem(basketItem)}
            alt={product.name}
            width={70}
            height={70}
          />
        </div>
        <div className="flex flex-col gap-8">
          <TextLabel size="large" weight={"medium"} asChild>
            <p>{basketItemName || productName}</p>
          </TextLabel>

          <TextLabel size="medium" asChild>
            <div className="flex flex-col gap-4">
              {isStale ? (
                <p>Product needs updating - click 'View Basket' to view updates</p>
              ) : (
                <>
                  <p>{renderDetails()}</p>
                  <p>{renderLCADetails()}</p>
                  {!isCustomQuote && results?.speed?.human_readable && (
                    <p>{results?.speed?.human_readable}</p>
                  )}
                </>
              )}
            </div>
          </TextLabel>
        </div>

        <div className="flex whitespace-nowrap">
          {!isStale && (
            <Button
              variant={"tertiary"}
              size="sm"
              layout={"iconOnly"}
              data-cy="edit-basket-item-link"
              asChild
            >
              <Link href={editLink} passHref data-cy="edit-basket-item-link" prefetch={false}>
                <IconDocumentPencil />
              </Link>
            </Button>
          )}
          <Button
            variant={"tertiary"}
            size="sm"
            layout={"iconOnly"}
            onClick={handleDelete}
            data-cy="delete-basket-item-button"
            type="button"
          >
            <IconNavigationTrash />
          </Button>
        </div>
      </div>
    </li>
  );
};
