import { getAttributeDataById } from "@lib/utils/getAttributeDataById";
import { isProductStale } from "@lib/utils/isProductStale";
import {
  AttributeSelection,
  BasketItem,
  ConfigurationItem,
  ProductMain,
  ProductMigrationWarning,
  ProductMigrationWarningType,
  ProductType,
  SanityProductPreset,
} from "@sourceful/shared-types";
import { getAttributeShortId } from "@sourceful/shared-utils/product-utils";
import { doesAttributeAffectDieline } from "./doesAttributeAffectDieline";
import { handleAttributeMigration } from "./handleAttributeMigration";

export interface GetProductMigrationWarningsArgs<T extends ConfigurationItem | BasketItem> {
  item: T;
  latestProductVersion: ProductMain;
  defaultPreset?: SanityProductPreset;
  isPreview?: boolean;
}
export interface ProductMigrationWarningsWithItem<T extends ConfigurationItem | BasketItem> {
  item: T;
  warnings?: ProductMigrationWarning[];
  isStale: boolean;
}

export const getProductMigrationWarnings = <T extends ConfigurationItem | BasketItem>({
  item,
  latestProductVersion,
  defaultPreset,
  isPreview = false,
}: GetProductMigrationWarningsArgs<T>): ProductMigrationWarningsWithItem<T> => {
  // check if new product version
  const { product } = item;

  const isStale = isProductStale({
    isActiveVersion: product.isActiveVersion,
    isLatestVersion: product.isCurrentVersion,
    isPreview,
    isRfq: product.type === ProductType.RFQ,
  });

  if (!isStale || latestProductVersion.versionId === product.versionId) {
    return { item, isStale: false };
  }

  const newAttributesSelection: ConfigurationItem["attributeSelection"] = {};
  const warnings: ProductMigrationWarning[] = [];

  // Helper functions
  const updateAttributesSelection = (attributeId: string, newSelection?: AttributeSelection) => {
    if (!newSelection) {
      if (defaultPreset && defaultPreset.attributes[attributeId]) {
        newAttributesSelection[attributeId] = defaultPreset.attributes[attributeId];
        return;
      }

      const newAttrId = latestProductVersion.attributes.ids.find(attrId => attrId === attributeId);
      const newAttr = getAttributeDataById(latestProductVersion, newAttrId || "") || null;

      const fallback = newAttr.options[0];

      if (!fallback) {
        console.error(`Could not find fallback value for attribute id ${attributeId}`);
      }

      newAttributesSelection[attributeId] = {
        attribute: newAttr,
        value: fallback.value,
      };

      return;
    }
    newAttributesSelection[attributeId] = newSelection;
  };

  const pushWarning = (warning: ProductMigrationWarning) => {
    warnings.push(warning);
  };

  // Process attributes
  for (let newAttributeId of latestProductVersion.attributes.ids) {
    const newAttribute = getAttributeDataById(latestProductVersion, newAttributeId);

    newAttribute &&
      handleAttributeMigration({
        currentSelection: item.attributeSelection,
        newAttribute,
        currentProductVersion: product,
        latestProductVersion,
        updateAttributesSelection,
        pushWarning,
      });
  }

  // / find the attributes from the old product that are no longer referenced in the new product definition at all (not even as a modified attribute)
  for (const [attributeId, selection] of Object.entries(item.attributeSelection)) {
    const shortId = getAttributeShortId(attributeId, item.product.baseProductId);

    const hasAttributeBeenDeleted = !latestProductVersion.attributes.ids.find(
      attrId => attrId === attributeId || attrId === shortId
    );

    const wasOldAttributeVisible = !!item.product.productConfigSections?.some(
      sections => !!sections?.attributes?.some(i => i.attributeId === attributeId)
    );

    if (hasAttributeBeenDeleted && wasOldAttributeVisible) {
      warnings.push({
        oldAttribute: attributeId,
        newAttribute: null,
        type: ProductMigrationWarningType.ATTRIBUTE_DELETED,
        isDielineAffected: doesAttributeAffectDieline(selection.attribute),
      });
    }
  }

  // Update item in db
  const updatedItem: T = {
    ...item,
    attributeSelection: newAttributesSelection,
    preset: null,
    product: latestProductVersion,
    artworkThumbnails: undefined,
  };

  return {
    item: updatedItem,
    warnings: warnings.length >= 1 ? warnings : undefined,
    isStale: true,
  };
};
