import { ProductMain } from "@sourceful/shared-types";

export const getAttributeDataById = (
  product: ProductMain,
  attrId: string,
  disableError?: boolean
) => {
  if (!product.attributes.entities[attrId] && !disableError) {
    console.error("Could not find attribute data", { attrId, product });
  }
  return product.attributes.entities[attrId];
};
