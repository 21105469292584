import { useAuthentication } from "@app/providers/AuthenticationProvider/AuthenticationProvider";
import { CurrentBasketQuery } from "@generated/graphql";
import { Basket, BasketItem, ProductMain, SanityProductPreset } from "@sourceful/shared-types";
import { useQuery } from "@tanstack/react-query";
import { useMemo } from "react";
import { formatCurrentBasketQuery } from "../helpers/basket-format-helpers";
import { ProductMigrationWarningsWithItem } from "../helpers/basketMigration/getProductMigrationWarnings";
import { sortStaleConfigItems } from "../helpers/basketMigration/sortStaleConfigItems";
import { automigrateBasketItems } from "./helpers/automigrateBasketItems";

export interface UseFormatRawBasket {
  rawBasket: CurrentBasketQuery["product_cloud_basket"][0] | null;
  isPreview: boolean;
  products: ProductMain[] | null;
  presets: SanityProductPreset[];
}

export interface FormattedBasketState {
  basket: Basket;
  staleProductWarnings: ProductMigrationWarningsWithItem<BasketItem>[];
}

/*
  A hook that takes a raw basket and applies formatting + migrates any stale items that can be auto-migrated
  Returns the formatted basket and any warnings for stale items that can't be automigrated
*/

export const useFormattedBasket = ({
  rawBasket,
  isPreview,
  products,
  presets,
}: UseFormatRawBasket) => {
  const formattedBasketQueryKey = useMemo(() => [rawBasket, isPreview], [rawBasket, isPreview]);
  const { getAccessTokenSilently } = useAuthentication();

  const {
    data: formattedBasket,
    isInitialLoading: formattedBasketLoading,
    isRefetching: formattedBasketRefetching,
    error: formattedBasketError,
  } = useQuery<unknown, unknown, FormattedBasketState>({
    queryKey: formattedBasketQueryKey,
    queryFn: async () => {
      if (!products) return;

      if (!rawBasket) {
        return null;
      }

      console.debug("useFetchBasket - fetching formatted basket");
      let formattedBasket = formatCurrentBasketQuery(rawBasket, products, presets);

      const { staleItemsWithoutWarnings, staleItemsWithWarnings } = sortStaleConfigItems(
        formattedBasket.configurationItems,
        products,
        presets,
        !!isPreview
      );

      const token = await getAccessTokenSilently();

      // migrate any items that are stale but can be upgraded without warnings
      formattedBasket = await automigrateBasketItems(
        formattedBasket,
        staleItemsWithoutWarnings,
        token
      );

      return { basket: formattedBasket, staleProductWarnings: staleItemsWithWarnings };
    },
    enabled: !!products,
    staleTime: 0,
    gcTime: 0,
  });

  return {
    formattedBasket,
    formattedBasketLoading,
    formattedBasketRefetching,
    formattedBasketError,
    formattedBasketQueryKey,
  };
};
