import { ConfigurationItem, Basket, BasketItem, ProductType } from "@sourceful/shared-types";
import { getSanityImgUrl } from "@lib/sanity";
import { apiConfig } from "../../../apiConfig";
import { isProductStale } from "@lib/utils/isProductStale";
const CLOUD_BUCKET_THUMBS = apiConfig.cloudBucketThumbnails;

export const getTotalBasketItems = (basket: Basket) => basket.configurationItems.length;

export const getThumbnailUrlForBasketItem = (
  basketItem: ConfigurationItem | BasketItem,
  width: number = 500,
  height: number = 500
) => {
  const { product, heroThumbnail } = basketItem;

  if (heroThumbnail) return `${CLOUD_BUCKET_THUMBS}/${heroThumbnail.low_res}`;

  if (product.images && product.images.length > 0) {
    return getSanityImgUrl(product.images[0].asset._ref, base => base.height(height).width(width));
  }

  return "";
};

export const filterStaleItemsFromBasket = (basket: Basket, isPreview: boolean): Basket => {
  let basketWithoutStaleItems: Basket = {
    ...basket,
    configurationItems: basket.configurationItems.filter(item => {
      const isStale = isProductStale({
        isActiveVersion: item.product.isActiveVersion,
        isLatestVersion: item.product.isCurrentVersion,
        isPreview,
        isRfq: item.product.type === ProductType.RFQ,
      });

      return !isStale;
    }),
  };

  return basketWithoutStaleItems;
};
