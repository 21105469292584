import { SanityProductPreset } from "@sourceful/shared-types";

export const getDefaultPresetForProduct = (
  presets: SanityProductPreset[],
  baseProductId: number,
  versionId: number
) => {
  return presets.find(
    preset => preset.baseProductId === baseProductId && preset.baseProductVersionId === versionId
  );
};
