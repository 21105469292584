import {
  MessageMeta,
  useMessageListener,
  useMessagePublisher,
} from "@lib/simpleMessagePublisher/useMessagePublisher";
import { ConfigurationItem } from "@sourceful/shared-types";
import Router from "next/router";
import { useCallback } from "react";

/* 
    This hook handles detection of multiple configurator tabs being open with the same config item
    If an update is made in one tab, the other tabs route to the homepage to avoid data drift
*/

export interface ConfigurationUpdate {
  configItemId: ConfigurationItem["id"];
}

export interface UseDetectDuplicateConfiguratorTabsProps {
  currentConfigItemId: ConfigurationItem["id"] | null;
}

export const CONFIGURATION_TOPIC = "configuration";
export const useDetectDuplicateConfiguratorTabs = ({
  currentConfigItemId,
}: UseDetectDuplicateConfiguratorTabsProps) => {
  const handleStateUpdate = useCallback(
    (message: ConfigurationUpdate & MessageMeta) => {
      // check config item id
      if (!currentConfigItemId || currentConfigItemId !== message.configItemId) {
        return;
      }

      console.log("This config item is being updated in another tab, closing configurator");
      Router.push("/");
    },
    [currentConfigItemId]
  );

  // listener
  useMessageListener<ConfigurationUpdate>({
    topic: CONFIGURATION_TOPIC,
    callback: handleStateUpdate,
  });

  // publisher
  const { publish } = useMessagePublisher(CONFIGURATION_TOPIC);

  const publishConfigurationUpdateEvent = useCallback(() => {
    if (!currentConfigItemId) return;
    publish({
      configItemId: currentConfigItemId,
    });
  }, [publish, currentConfigItemId]);

  return { publishConfigurationUpdateEvent };
};
