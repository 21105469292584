import { AttributeSelection, AttributeValueType, ProductMain } from "@sourceful/shared-types";
import { getAttributeShortId } from "../product-utils/getAttributeShortId";
import { removeNulls } from "../sanity-utils/fetchers/ProductFetcher";

// takes the raw selected attributes values as stored in the db/sanity and decorates with the attribute metadata
export const decorateAttributeValues = (
  attributes: {
    [attributeId: string]: AttributeValueType | AttributeValueType[];
  },
  product: ProductMain
) => {
  const attributeMetaById = product.attributes.entities;

  return Object.keys(attributes).reduce((result, attributeId) => {
    //attributeId for legacy items may be using the longId - therefore coerce into shortId to access metadata from the product attributes
    const shortId = getAttributeShortId(attributeId, product.baseProductId);
    const selection: AttributeSelection = {
      attribute: attributeMetaById[shortId],
      //using key that was in the selection irrespective if its the shortId
      value: removeNulls(attributes[attributeId]) as AttributeValueType,
    };

    //final output for the attribute selection we will always use the shortId
    result[shortId] = selection;

    return result;
  }, {} as { [attributeId: string]: AttributeSelection });
};
