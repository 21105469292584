import { AttributeValueType, BasicValue, ColourValue, SizeValue } from "@sourceful/shared-types";

export function isKeyTruthy(obj: any, key: string | number | symbol): boolean {
  return key in obj && obj[key] !== null && obj[key] !== undefined;
}

export const sizeValueToString = (val: SizeValue) => {
  const { height, length, width, unit } = val;

  const dims = [
    { label: "L", value: length },
    { label: "W", value: width },
    { label: "H", value: height },
  ].filter(i => i.value !== undefined && i.value !== null);

  let output: string[] = [];

  dims.forEach(dim => {
    output.push(`${dim.label}: ${dim.value}${unit}`);
  });

  return output.join(", ");
};

export const getAttributeValueString = (
  value: AttributeValueType | AttributeValueType[]
): string => {
  if (Array.isArray(value)) {
    return value.map(getAttributeValueString).join(", ");
  } else if (isKeyTruthy(value, "value")) {
    return (value as BasicValue).value;
  } else if (
    isKeyTruthy(value, "width") ||
    isKeyTruthy(value, "height") ||
    isKeyTruthy(value, "length")
  ) {
    return sizeValueToString(value as SizeValue);
  } else if (isKeyTruthy(value, "values")) {
    const pantone = (value as ColourValue).values.pantone;
    return `${pantone.code} ${pantone.colourLibrary}`;
  }
  return "";
};
