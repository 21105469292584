import { ProductMain } from "@sourceful/shared-types";

export const getLatestProductVersion = (
  baseProductId: number,
  products: ProductMain[]
): ProductMain | null => {
  let output: ProductMain | null = null;

  for (let product of products) {
    if (baseProductId !== product.baseProductId) continue;

    if (!output || (product.versionId > output.versionId && product.isActiveVersion)) {
      output = product;
    }
  }

  return output;
};
