import {
  AttributeSelection,
  ConfigurationItem,
  ProductAttribute,
  ProductMain,
  ProductMigrationWarning,
  ProductMigrationWarningType,
} from "@sourceful/shared-types";
import { doesAttributeAffectDieline } from "./doesAttributeAffectDieline";

export interface HandleAttributeMigrationArgs {
  currentSelection: ConfigurationItem["attributeSelection"];
  updateAttributesSelection: (attributeId: string, newSelection?: AttributeSelection) => void;
  pushWarning: (warning: ProductMigrationWarning) => void;
  newAttribute: ProductAttribute;
  latestProductVersion: ProductMain;
  currentProductVersion: ProductMain;
}

export const handleAttributeMigration = ({
  currentSelection,
  updateAttributesSelection,
  pushWarning,
  newAttribute,
  latestProductVersion,
  currentProductVersion,
}: HandleAttributeMigrationArgs) => {
  const newAttrId = newAttribute.id; // product attributes use shortId by default now
  const oldAttributeId =
    Object.keys(currentSelection).find(attrId => attrId === newAttrId) || newAttrId;

  // attribute existed in previous product version
  const selection = oldAttributeId ? currentSelection[oldAttributeId] : null;

  const isNewAttributeVisible = !!latestProductVersion.productConfigSections?.some(
    section => !!section.attributes?.some(attribute => attribute.attributeId === newAttribute.id)
  );

  const wasOldAttributeVisible = !!currentProductVersion.productConfigSections?.some(
    section => !!section.attributes?.some(attribute => attribute.attributeId === oldAttributeId)
  );

  if (!selection) {
    // attribute didn't exist in previous product version
    updateAttributesSelection(newAttribute.id); // use default value for preset

    // only show warning if the new attribute is visible
    if (!isNewAttributeVisible) return;

    return pushWarning({
      oldAttribute: null,
      newAttribute: newAttribute.id,
      type: ProductMigrationWarningType.NEW_ATTRIBUTE,
      isDielineAffected: doesAttributeAffectDieline(newAttribute),
    });
  }

  // check if the selected value is still valid

  // array value
  if (Array.isArray(selection.value)) {
    let isValidArray = true;

    for (let value of selection.value) {
      const selectedValueId = value.id;
      if (
        !isSelectedValueValidForLatestProduct(
          selectedValueId,
          newAttribute.options,
          Boolean(newAttribute.allowCustomOption)
        )
      ) {
        isValidArray = false;
      }
    }

    if (isValidArray) {
      if (updateAttributesSelection)
        updateAttributesSelection(newAttribute.id, {
          attribute: newAttribute,
          value: selection.value,
        });

      return;
    }

    updateAttributesSelection(newAttribute.id); // use default value for preset

    // don't show warning if this attribute was never visible
    if (!wasOldAttributeVisible && !isNewAttributeVisible) return;

    return pushWarning({
      oldAttribute: oldAttributeId,
      newAttribute: newAttribute.id,
      type: ProductMigrationWarningType.OPTIONS_CHANGED,
      isDielineAffected: doesAttributeAffectDieline(newAttribute),
    });
  }

  // single value
  const selectedValueId = selection.value.id;
  if (
    isSelectedValueValidForLatestProduct(
      selectedValueId,
      newAttribute.options,
      Boolean(newAttribute.allowCustomOption)
    )
  ) {
    updateAttributesSelection(newAttribute.id, {
      attribute: newAttribute,
      value: selection.value,
    });
    return;
  }

  // If selected value is invalid, default to preset value
  updateAttributesSelection(newAttribute.id);

  // don't show warning if this attribute was never visible
  if (!wasOldAttributeVisible && !isNewAttributeVisible) return;

  pushWarning({
    oldAttribute: oldAttributeId,
    newAttribute: newAttribute.id,
    type: ProductMigrationWarningType.OPTIONS_CHANGED,
    isDielineAffected: doesAttributeAffectDieline(newAttribute),
  });
};

export const isSelectedValueValidForLatestProduct = (
  selectedValueId: string,
  latestAttributeOptions: ProductAttribute["options"],
  isCustomAllowed: boolean
) => {
  // TODO: do something more sophisticated here - ideally check the size validations
  if (isCustomAllowed) return true;
  return latestAttributeOptions.some(option => option.value.id === selectedValueId);
};
