"use client";

import { useAuthentication } from "@app/providers/AuthenticationProvider/AuthenticationProvider";
import { useBasketContext } from "@app/providers/BasketProvider/BasketProvider";
import { useWindowSize } from "@react-hook/window-size";
import {
  Button,
  IconShoppingBasket,
  IconShoppingCartFull,
  Popover,
  PopoverContent,
  PopoverTrigger,
  TextBody,
  screens,
} from "@sourceful/design-system-v3";
import { usePathname } from "next/navigation";
import React, { useCallback, useEffect, useRef, useState } from "react";
import useMedia from "react-use/lib/useMedia";
import BasketContent from "./BasketContent";

interface BasketMenuProps {}

const BasketMenu = React.forwardRef<HTMLButtonElement, BasketMenuProps>(({ ...props }, ref) => {
  const [visible, setVisible] = useState(false);

  const { isAuthenticated, isLoading: authIsLoading } = useAuthentication();

  const { basket, isFetchingBasket, initialised, staleProductWarnings, removeBasketItem } =
    useBasketContext();

  const loaded = !isFetchingBasket && initialised;
  const hasBasketItems = basket.configurationItems.length > 0;

  const refViewBasket = useRef<HTMLAnchorElement>(null);

  const [width] = useWindowSize();

  const isMd = useMedia(`(min-width: ${screens.md})`, true);

  useEffect(() => {
    if (width > 0) {
      setVisible(false);
    }
  }, [width]);

  const onMouseEnter = () => {
    // Only set to visible on larger screens
    if (isMd) {
      setVisible(true);
    }
  };

  const popoverContentContainerRef = useRef<HTMLDivElement>(null);

  const pathname = usePathname();

  const closeOnPathnameChange = useCallback(() => {
    setVisible(false);
  }, [pathname]);

  if (!isAuthenticated || !loaded) {
    return;
  }

  return (
    <div
      ref={popoverContentContainerRef}
      onMouseLeave={() => {
        setVisible(false);
      }}
    >
      <Popover
        open={visible}
        onOpenChange={open => {
          setVisible(open);
        }}
      >
        <PopoverTrigger onMouseEnter={onMouseEnter} asChild>
          <Button
            ref={ref}
            variant="tertiary"
            size="md"
            layout="iconOnly"
            {...props}
            disabled={authIsLoading}
            data-cy="basket-menu-button"
            className={`text-inherit 
            hover:text-ui-darkest 
            duration-300 disabled:bg-transparent 
            disabled:text-inherit disabled:opacity-50
            relative hover:before:absolute hover:before:-inset-8 hover:before:-bottom-32`}
          >
            {hasBasketItems ? (
              <IconShoppingCartFull title="Basket" />
            ) : (
              <IconShoppingBasket title="Empty basket" />
            )}
          </Button>
        </PopoverTrigger>
        <PopoverContent
          close={false}
          sideOffset={16}
          onOpenAutoFocus={e => {
            e.preventDefault();
            // Focus our view basket button by default when opening (otherwise its a delete button, not ideal)
            refViewBasket.current?.focus();
          }}
          fullScreen="until-md"
        >
          <div className="flex flex-col gap-12 p-24 w-full min-w-[250px] text-brand-black">
            <TextBody size="medium" weight="medium" asChild>
              <p>Basket</p>
            </TextBody>
            {loaded && (
              <BasketContent
                basket={basket}
                staleProductWarnings={staleProductWarnings}
                removeBasketItem={removeBasketItem}
                handleClose={() => {
                  closeOnPathnameChange();
                }}
                ref={refViewBasket}
              />
            )}
          </div>
        </PopoverContent>
      </Popover>
    </div>
  );
});

export default BasketMenu;
