import gql from "graphql-tag";

export const ADDRESS_FRAGMENT = gql`
  fragment addressFields on product_cloud_address {
    id
    name
    org_id
    phone_number
    postcode
    user_id
    address_line_1
    address_line_2
    address_line_3
    address_description
    city
    country_code
  }
`;
