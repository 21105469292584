import env from "@lib/utils/env";
import { EngineVersionManager, GDS_VERSION } from "@sourceful/shared-utils/engine-utils";
export const OVERRIDE_KEY = "ENGINE_GDS_VERSION_OVERRIDE";

const SOURCEFUL_ROOT_DOMAIN = env("ROOT_HOSTNAME");
const defaultVersion = env("GDS_VERSION");
const versionManager = new EngineVersionManager(defaultVersion, SOURCEFUL_ROOT_DOMAIN);

export const getEngineVersion = () => {
  return versionManager.getEngineVersion();
};

export const setEngineVersionOverride = (version: GDS_VERSION) => {
  versionManager.setEngineVersion(version);
};

export const getDefaultEngineVersion = () => {
  return versionManager.getDefaultVersion();
};

export const clearEngineOverride = () => {
  versionManager.clearEngineVersion();
};
