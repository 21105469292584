import { ErrorStatus } from "@app/providers/ErrorProvider/ErrorProvider";
import { usePageContext } from "@app/providers/PageProvider/PageProvider";
import { getClient } from "@lib/sanity";
import { ProductMain } from "@sourceful/shared-types";
import { fetchProducts } from "@static-data/helpers/fetchProducts";
import { useQuery } from "@tanstack/react-query";
import { useCallback } from "react";
import { i18n } from "../../next-i18next.config";
import { useHandleQueryError } from "./useHandleQueryError";

export interface ProductIds {
  baseProductId: number;
  versionId: number;
}

export const useProducts = (lazy: boolean = true) => {
  const { draftMode: isPreview, locale } = usePageContext();
  const currentLocale = locale || i18n.defaultLocale;

  const {
    data: products,
    isInitialLoading: productsLoading,
    error,
  } = useQuery({
    queryKey: ["/api/sanity/products", isPreview, currentLocale],
    queryFn: async () => {
      console.debug("useProducts - fetching products");
      const client = getClient(isPreview, true);
      return fetchProducts({ isPreview, locale: currentLocale, client });
    },
    staleTime: Infinity,
    gcTime: Infinity,
    enabled: !lazy,
  });

  useHandleQueryError({
    error,
    message: "Error fetching products",
    scope: "Products",
    status: ErrorStatus.local,
    type: "report",
  });

  const getProductById = useCallback(
    ({ baseProductId, versionId }: ProductIds): ProductMain | null => {
      const productsById = sortProductsByIds(products || []);

      const productVersions = productsById[baseProductId];
      const product = productVersions ? productVersions[versionId] : null;

      return product;
    },
    [products]
  );

  return {
    products: products || null,
    getProductById,
    productsLoading,
    productsLoadingError: error,
  };
};

interface ProductsById {
  [baseProductId: number]: { [versionId: number]: ProductMain };
}

export const sortProductsByIds = (products: ProductMain[]): ProductsById => {
  return products.reduce((output, current) => {
    if (!output[current.baseProductId]) {
      output[current.baseProductId] = {};
    }

    output[current.baseProductId][current.versionId] = current;
    return output;
  }, {} as ProductsById);
};
