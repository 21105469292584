import { BASKET_FRAGMENT } from "@gql/fragments/basket/basketFragment";
import gql from "graphql-tag";

export const CURRENT_BASKET = gql`
  query CurrentBasket($user_id: String!) {
    product_cloud_basket(
      where: { user_id: { _eq: $user_id }, status_id: { _eq: 1 }, type: { _eq: "LIVE_PRICE" } }
    ) {
      ...basketFields
    }
  }
  ${BASKET_FRAGMENT}
`;
