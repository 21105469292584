import { ErrorStatus, useErrorContext } from "@app/providers/ErrorProvider/ErrorProvider";
import { usePageContext } from "@app/providers/PageProvider/PageProvider";
import { fetchPresets } from "@lib/utils/presets";
import { SanityProductPreset } from "@sourceful/shared-types";
import { Crawler } from "es6-crawler-detect";
import keyBy from "lodash/keyBy";
import { useCallback } from "react";
import useSWR from "swr";
import { i18n } from "../../next-i18next.config";

const CrawlerDetector = new Crawler();

export const usePresets = (lazy: boolean = true) => {
  const { reportError } = useErrorContext();

  const { draftMode: isPreview } = usePageContext();

  // use immutable to stop automatic revalidation as presets data is static
  const {
    data: presets,
    isLoading,
    error,
    mutate,
  } = useSWR(["/api/sanity/presets", isPreview, lazy], lazy ? null : fetchSourcefulPresets, {
    onError: () => {
      reportError({
        message: "Error fetching presets",
        stack: error,
        status: ErrorStatus.local,
      });
    },
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });

  const presetsLoading = !presets && !error && isLoading;

  const getPresetsAsync = useCallback(async () => {
    let _presets = presets;

    if (presetsLoading) {
      // if attempting to get presets before the presets are loaded, await preset load before proceeding
      _presets = await mutate();
    }

    return _presets || [];
  }, [presets, mutate, presetsLoading]);

  const getPresetById = useCallback(
    (presetId: string): SanityProductPreset | null => {
      const sourcefulPresetsById = keyBy(presets, "id");

      return sourcefulPresetsById[presetId] || null;
    },
    [presets]
  );

  return { presets: presets || [], getPresetsAsync, presetsLoading, error, getPresetById };
};

const fetchSourcefulPresets = async ([_, isPreview]: [url: string, isPreview: boolean]) => {
  // don't fetch presets if user is a bot as it will likely trigger 499 errors (client closed request)
  if (typeof window !== "undefined" && CrawlerDetector.isCrawler(window?.navigator?.userAgent)) {
    return [];
  }

  console.debug("usePresets - fetching presets");

  return fetchPresets({
    isPreview,
    locale: i18n.defaultLocale,
  });
};
