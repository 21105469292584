import { SanityProductPreset } from "@sourceful/shared-types";
import { getClient } from "@lib/sanity";
import { SanityPresetFetcher } from "@sourceful/shared-utils/sanity-utils";

export type FetchPresets = (args: {
  isPreview: boolean;
  locale: string;
  productIds?: string[];
}) => Promise<SanityProductPreset[]>;

export const fetchPresets: FetchPresets = async ({ isPreview, locale, productIds }) => {
  const client = getClient(isPreview);
  const presetFetcher = new SanityPresetFetcher({ client, locale });

  if (!productIds?.length) {
    return presetFetcher.fetchPresets();
  }

  return presetFetcher.fetchPresetsForProducts(productIds);
};
