import { SanityClient } from "@sanity/client";
import { ProductMain } from "@sourceful/shared-types";
import { SanityProductFetcher } from "@sourceful/shared-utils/sanity-utils";

export type FetchProducts = (args: {
  isPreview: boolean;
  locale: string;
  client: SanityClient;
}) => Promise<ProductMain[]>;

export const fetchProducts: FetchProducts = async ({ isPreview, locale, client }) => {
  // console.debug("fetchProducts", { isPreview, locale });

  const productFetcher = new SanityProductFetcher({ client, locale, isPreview });
  // fetch all products as sometimes user might have a deprecated product - we want to be able to gracefully signal
  // that the product is graceful, distinguishing from not finding the product at all
  let products = await productFetcher.fetchAll({ activeOnly: false, coreFieldsOnly: true });
  let draftProducts: { [id: string]: ProductMain } = {};

  let finalProducts: { [id: string]: ProductMain } = {};

  if (isPreview) {
    products.forEach(product => {
      if (product.id.includes("drafts.")) {
        const truncatedId = product.id.replace("drafts.", "");
        draftProducts[truncatedId] = { ...product, id: truncatedId };
      } else {
        finalProducts[product.id] = product;
      }
    });

    // overwrite any published products with draft equivalent if draft exists
    // also adds any previously unpublished products
    Object.keys(draftProducts).forEach(productId => {
      finalProducts[productId] = draftProducts[productId];
    });

    return Object.values(finalProducts);
  }

  return products;
};

let productCache: { products: ProductMain[] | null; preview: boolean } = {
  products: null,
  preview: false,
};

export const clearProductPreviewCache = () => {
  productCache = { products: null, preview: false };
  console.log("Removed product preview cache", productCache);
};
