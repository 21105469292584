import {
  MaxPrintableSides,
  BasicValue,
  AttributeSelection,
  PrintArea,
  FaceName,
} from "@sourceful/shared-types";
import { getSelectedPrintAreas } from "./getSelectedPrintAreas";

// handles both new and legacy printAreas / printSides
export const getPrintSidesForArtworkUpload = (
  selectedAttributes: AttributeSelection[],
  maxPrintableSides: MaxPrintableSides | undefined
): FaceName[] => {
  // TODO: replace FaceName with PrintArea once inner and outer are deprecated
  // handle new wait of getting printAreas
  if (!maxPrintableSides) {
    return getSelectedPrintAreas(selectedAttributes);
  }

  if (maxPrintableSides === MaxPrintableSides.SINGLE_SIDED) {
    // TODO: remove id checks when new sanity product types are updated with printColour type
    const printColour = selectedAttributes.find(
      selection =>
        selection.attribute.type === "printColour" ||
        selection.attribute.id.includes("print_colour") ||
        selection.attribute.id.includes("print_type")
    );

    if (printColour && (printColour?.value as BasicValue)?.value !== "Blank") {
      return [PrintArea.SINGLE_SIDED];
    }

    return []; // equivalent of old 'NO_PRINT'
  }

  const printColourInner = selectedAttributes.find(
    selection => selection.attribute.type === "printColourInner"
  );

  const printColourInnerValue = (printColourInner?.value as BasicValue)?.value || null;

  const printColourOuter = selectedAttributes.find(
    selection => selection.attribute.type === "printColourOuter"
  );

  const printColourOuterValue = (printColourOuter?.value as BasicValue)?.value || null;

  const hasInner = printColourInnerValue && printColourInnerValue !== "Blank";
  const hasOuter = printColourOuterValue && printColourOuterValue !== "Blank";

  if (hasInner && !hasOuter) {
    return ["inner"];
  }

  if (hasInner && hasOuter) {
    return ["outer", "inner"];
  }

  if (hasOuter && !hasInner) {
    return ["outer"];
  }

  return [];
};
