import { useAuthentication } from "@app/providers/AuthenticationProvider/AuthenticationProvider";
import { useFinalMileContext } from "@app/providers/FinalMileProvider/FinalMileProvider";
import { getEngineVersion } from "@lib/getEngineVersion";
import {
  Basket,
  BasketItem,
  BasketTotals,
  EngineMetadataSummaryByBasketItemId,
} from "@sourceful/shared-types";
import { useQuery } from "@tanstack/react-query";
import { useMemo } from "react";
import { fetchBasketTotals } from "../_api";
import { ProductMigrationWarningsWithItem } from "../helpers/basketMigration/getProductMigrationWarnings";

export interface UseBasketTotals {
  basket: Basket | null;
  staleProductWarnings: ProductMigrationWarningsWithItem<BasketItem>[];
}
export const useBasketTotals = ({ basket, staleProductWarnings }: UseBasketTotals) => {
  const { finalMile } = useFinalMileContext();
  const { getAccessTokenSilently } = useAuthentication();

  const basketTotalsQueryKey = useMemo(
    () => [
      basket?.id || null, // Note that this means that totals won't update if items change - this is handled by the updateBasketMutation
      finalMile.postcode,
      finalMile.countryCode,
    ],
    [basket?.id, finalMile.postcode, finalMile.countryCode]
  );

  const {
    data: basketTotals,
    isInitialLoading: basketTotalsLoading,
    isFetching: basketTotalsRefetching,
    refetch: refetchBasketTotals,
    error: basketTotalsError,
  } = useQuery({
    queryKey: basketTotalsQueryKey,
    queryFn: async () => {
      if (!basket) {
        return null;
      }

      const token = await getAccessTokenSilently();
      let engineQuotesByConfigItemId: EngineMetadataSummaryByBasketItemId = {};
      let totals: BasketTotals | null = null;
      console.debug("useFetchBasket - fetchingBasketTotals");
      // we don't want to fetch totals if the basket contains stale items as this will cause an error in basket fetching
      // in future we should move all basket fetching behind a query in our custom graphql server
      // this will allow us to move the migration logic to the backend and be more granular in the pricing we fetch (e.g. we can price non-stale items)
      if (staleProductWarnings.length === 0) {
        const { engineQuotesByConfigItemId: _engineQuotesByConfigItemId, ..._totals } =
          await fetchBasketTotals({
            payload: {
              basketId: basket.id as string,
              postcode: finalMile.postcode,
              countryCode: finalMile.countryCode,
              saveEngineQuote: false,
              gdsVersion: getEngineVersion(),
            },
            token,
          });

        engineQuotesByConfigItemId = _engineQuotesByConfigItemId;
        totals = _totals;

        return {
          engineQuotesByConfigItemId,
          totals,
        };
      }
    },
    staleTime: 0,
    gcTime: 0,
  });

  return {
    basketTotals,
    basketTotalsLoading,
    basketTotalsRefetching,
    refetchBasketTotals,
    basketTotalsError,
    basketTotalsQueryKey,
  };
};
