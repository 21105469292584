import getAxiosClient from "@lib/getAxiosClient";
import {
  BasketPayload,
  BasketTotals,
  CalculateBasketTotalsPayload,
  EngineMetadataSummaryByBasketItemId,
} from "@sourceful/shared-types";
import axios from "axios";
import { apiConfig } from "../../../apiConfig";

export const syncBasket = async ({
  payload,
  token,
}: {
  payload: BasketPayload;
  token: string | null;
}) => {
  const response = await axios.put(apiConfig.productCloudApiOrigin + "/basket", payload, {
    headers: {
      authorization: token ? `Bearer ${token}` : "",
      "x-hasura-user-id": "",
    },
  });

  return response;
};

interface AddBasketItemPayload {
  basketId: string;
  configurationItemId: string;
  token: string;
}

export const addBasketItemRecord = async ({
  basketId,
  configurationItemId,
  token,
}: AddBasketItemPayload) => {
  const client = getAxiosClient(token, {
    baseURL: apiConfig.productCloudApiOrigin,
  });

  const response = await client.post(`/basket/basket-item`, {
    basketId,
    configurationItemId,
  });

  return response?.data;
};

export interface FetchBasketTotalsArgs {
  payload: CalculateBasketTotalsPayload;
  token: string;
}

export type FetchBasketTotalsResponse = {
  engineQuotesByConfigItemId: EngineMetadataSummaryByBasketItemId;
} & BasketTotals;

export const fetchBasketTotals = async ({
  payload: { basketId, postcode, countryCode, saveEngineQuote = true, gdsVersion },
  token,
}: FetchBasketTotalsArgs): Promise<FetchBasketTotalsResponse> => {
  const client = getAxiosClient(token, {
    baseURL: apiConfig.productCloudApiOrigin,
  });

  const response = await client.post(`/basket/totals`, {
    basketId,
    postcode,
    countryCode,
    saveEngineQuote,
    gdsVersion,
  });

  return response?.data;
};

export type FetchCustomHandlingCostResponse = {
  customHandlingCostPence: number;
};

export const fetchCustomHandlingCost = async (
  token: string
): Promise<FetchCustomHandlingCostResponse> => {
  const client = getAxiosClient(token, {
    baseURL: apiConfig.productCloudApiOrigin,
  });

  const response = await client.get(`/basket/custom-handling-price`);

  return response?.data;
};
