import gql from "graphql-tag";
import { BASKET_FRAGMENT } from "@gql/fragments/basket/basketFragment";

export const ADD_BASKET = gql`
  mutation AddBasket(
    $currency: String!
    $user_id: String!
    $user_uuid_internal: String!
    $delivery_type: String!
    $delivery_metadata: jsonb
    $carbon_offset: Boolean = false
    $configuration_items: product_cloud_configuration_item_arr_rel_insert_input!
  ) {
    insert_product_cloud_basket_one(
      object: {
        autostock_requested: false
        carbon_offset: $carbon_offset
        currency: $currency
        status_id: 1
        user_id: $user_id
        user_uuid_internal: $user_uuid_internal
        configuration_items: $configuration_items
        delivery_type: $delivery_type
        delivery_metadata: $delivery_metadata
      }
    ) {
      ...basketFields
    }
  }
  ${BASKET_FRAGMENT}
`;
