import gql from "graphql-tag";

// TODO move to PC-api
export const INVALIDATE_ARTWORK_FILE_RESPONSE = gql`
  mutation deleteArtworkFileResponse($face: String!, $configurationItemId: uuid!) {
    delete_product_cloud_configuration_item_artwork(
      where: { face: { _eq: $face }, configuration_item_id: { _eq: $configurationItemId } }
    ) {
      affected_rows
    }

    update_product_cloud_configuration_item(
      where: { id: { _eq: $configurationItemId } }
      _set: { artwork_thumbnails: {} }
    ) {
      affected_rows
    }
  }
`;
