import gql from "graphql-tag";

// filter out complete and abandoned quotes
export const HEADER_COUNTS = gql`
  query HeaderCounts($user_id: String!) {
    product_cloud_configuration_item(
      where: {
        user_id: { _eq: $user_id }
        basket_id: { _is_null: true }
        quote_id: { _is_null: true }
      }
      order_by: { last_updated: desc }
    ) {
      id
    }
    product_cloud_quote(
      where: { user_id: { _eq: $user_id }, status_id: { _nin: [2, 3] } }
      order_by: { last_updated: desc }
    ) {
      id
    }
  }
`;
