import getAxiosClient from "@lib/getAxiosClient";
import {
  EngineParams,
  MigrateConfigItemProductPayload,
  MigrateConfigItemProductResult,
} from "@sourceful/shared-types";
import { ProductCloudRole } from "@sourceful/shared-utils/rbac";
import { apiConfig } from "../../../apiConfig";

export const generateProductName = async (
  token: string,
  enginePayload: EngineParams
): Promise<string> => {
  const client = getAxiosClient(token, {
    baseURL: apiConfig.engineApiOrigin,
  });

  const res = await client.post<string>(`/product_name`, enginePayload);
  return res?.data;
};

export const migrateConfigItemProduct = async (
  payload: MigrateConfigItemProductPayload,
  token: string,
  role?: ProductCloudRole
): Promise<MigrateConfigItemProductResult | null> => {
  const client = getAxiosClient(
    token,
    {
      baseURL: apiConfig.productCloudApiOrigin,
    },
    role
      ? {
          "x-hasura-role": role,
        }
      : undefined
  );

  const res = await client.put("/configuration-item/migrate", payload);
  return res?.data?.updatedItems[0] || null;
};
